import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import adminService from "../../services/admin.service";
import moment from "moment";
import TagsInput from "../base/TagsInput";
import userService from "../../services/user.service";
import kalenderService from "../../services/kalender.service";
import { messeAttr, objectAttr } from "../../constants/userAttributes";
import EditAbwesenheitProfile from "./EditAbwesenheitProfile";
import CircleLoader from "react-spinners/CircleLoader";

const Dienwuensche = (props) => {
  const dienwuenschAttributes = {
    Abwesenheit: { pretty: "Abwesenheiten", desc: "" },
    Dienwunsch: {
      pretty: "Dienwünsche",
      desc: "Die Termine, an denen du auf dem Dienplan stehen möchtest :)",
    },
    Dienpartner: {
      pretty: "Dienpartner",
      desc: "Es wird versucht, dass du mit mindestens einem deiner Dienpartner aufgestellt wirst",
    },
    Dienkommentar: { pretty: "Sonst. Kommentare", desc: "Sonst noch etwas?" },
    KeinWeihrauch: {
      pretty: "Weihrauch vermeiden?",
      desc: "Nur, wenn es wirklich GAR nicht mit Weihrauch geht :)",
    },
  };

  const [loading, setLoading] = useState(true);
  const [inputState, setInputState] = useState(false);
  const [currentDienwunschEdit, setCurrentDienwunschEdit] = useState(false);
  const [messen, setMessen] = useState();
  const [allNames, setAllNames] = useState();
  const [message, setMessage] = useState();

  const handleInputChange = (event) => {
    var state = { ...currentDienwunschEdit };
    state[event.target.name] =
      event.target.name === "KeinWeihrauch"
        ? !state.KeinWeihrauch
        : event.target.value;
    if (
      [
        "Abwesenheit",
        "MMS",
        "Mail_Haupt",
        "Dienpartner",
        "KeinWeihrauch",
        "Dienwunsch",
      ].includes(event.target.name)
    ) {
      editUser(false, state);
    } else {
      setMessage("Du hast ungespeicherte Änderungen.");
    }

    setCurrentDienwunschEdit(state);
    if (inputState.Geschwister.length > 0) {
      let geschwister = [...inputState["Geschwister"]];
      geschwister[geschwister.findIndex((ge) => ge._id == state._id)] = state;
      setInputState({ ...inputState, Geschwister: geschwister });
    } else {
      setInputState(state);
    }
  };

  const retrieveUser = () => {
    userService.getUser().then((res) => {
      userService.getAllNames().then((names) => {
        kalenderService
          .getMessen(
            moment().format("YYYY-MM-DD"),
            moment().add(5, "months").format("YYYY-MM-DD")
          )
          .then((messen) => {
            setAllNames(
              names.data.sort((a, b) => {
                if (a.Name < b.Name) return -1;
                if (a.Name > b.Name) return 1;
                if (a.Vorname < b.Vorname) return -1;
                if (a.Vorname > b.Vorname) return 1;
                return 0;
              })
            );
            setMessen(messen.data);
            setInputState(res.data);
            setLoading(false);
            if (currentDienwunschEdit === false)
              setCurrentDienwunschEdit(
                res.data.Geschwister.length > 0
                  ? res.data.Geschwister[0]
                  : res.data
              );
          });
      });
    });
  };

  const editUser = (event = false, state = false) => {
    if (event) event.preventDefault();
    userService.editDienwuensche(state ? state : inputState).then((res) => {
      setMessage("Änderungen wurden gespeichert!");
      retrieveUser();
    });
  };

  useEffect(() => {
    retrieveUser();
  }, []);

  return (
    <div className="container">
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          <div>
            {inputState["Geschwister"].length > 0 && (
              <div className="tabs">
                {inputState.Geschwister.map((geschwister) => (
                  <button
                    key={geschwister._id}
                    id={geschwister._id}
                    className={`tab-button ${
                      currentDienwunschEdit._id === geschwister._id
                        ? "select"
                        : ""
                    }`}
                    onClick={() => {
                      setCurrentDienwunschEdit(geschwister);
                    }}
                  >
                    {geschwister.Vorname}
                  </button>
                ))}
              </div>
            )}
            {currentDienwunschEdit && (
              <form key={currentDienwunschEdit._id} className="edit-form">
                <table>
                  <tbody>
                    {inputState.Geschwister.length > 0 && (
                      <tr>
                        <th>Geschwister</th>
                        <td data-tip data-for={"geschwister"}>
                          <ReactTooltip
                            id="geschwister"
                            place="top"
                            effect="solid"
                          >
                            Abwesenheiten und Dienwünsche können an andere
                            Geschwister übertragen werden.
                          </ReactTooltip>
                          <button
                            class="btn"
                            onClick={() => {
                              let { Abwesenheit, Dienwunsch } =
                                currentDienwunschEdit;
                              Abwesenheit = Abwesenheit.filter((a) =>
                                moment(a.Ende).isAfter(moment())
                              );
                              Dienwunsch = Dienwunsch.filter((a) =>
                                moment(
                                  messen[messen.findIndex((m) => m.id == a)]
                                    .Datum
                                ).isAfter(moment())
                              );
                              inputState.Geschwister.forEach((ge) => {
                                if (ge._id == currentDienwunschEdit._id) return;
                                setLoading(true);
                                editUser(false, {
                                  Abwesenheit,
                                  Dienwunsch,
                                  _id: ge._id,
                                });
                              });
                            }}
                          >
                            An Geschwister übertragen
                          </button>
                          {inputState.Geschwister.map((geschwister, i) =>
                            currentDienwunschEdit._id === geschwister._id ? (
                              <></>
                            ) : (
                              <button
                                class="btn"
                                key={"uebernahme" + geschwister._id}
                                id={geschwister._id}
                                onClick={() => {
                                  let { Abwesenheit, Dienwunsch } =
                                    inputState.Geschwister[i];
                                  Abwesenheit = Abwesenheit.filter((a) =>
                                    moment(a.Ende).isAfter(moment())
                                  );
                                  Dienwunsch = Dienwunsch.filter((a) =>
                                    moment(
                                      messen[messen.findIndex((m) => m.id == a)]
                                        .Datum
                                    ).isAfter(moment())
                                  );
                                  editUser(false, {
                                    Abwesenheit,
                                    Dienwunsch,
                                    _id: currentDienwunschEdit._id,
                                  });
                                }}
                              >
                                Von {geschwister.Vorname} übernehmen
                              </button>
                            )
                          )}
                        </td>
                      </tr>
                    )}
                    {Object.keys(dienwuenschAttributes).map((attr) => (
                      <tr key={attr + currentDienwunschEdit._id}>
                        <th>{dienwuenschAttributes[attr].pretty}</th>
                        <td data-tip data-for={"tooltip" + attr}>
                          {dienwuenschAttributes[attr].desc.length > 0 && (
                            <ReactTooltip
                              id={"tooltip" + attr}
                              place="top"
                              effect="solid"
                            >
                              {dienwuenschAttributes[attr].desc}
                            </ReactTooltip>
                          )}
                          {attr === "Abwesenheit" ? (
                            <EditAbwesenheitProfile
                              abwesenheit={currentDienwunschEdit[attr]}
                              onChange={handleInputChange}
                            />
                          ) : attr === "KeinWeihrauch" ? (
                            <input
                              name={attr}
                              type="checkbox"
                              checked={
                                currentDienwunschEdit[attr] === true
                                  ? true
                                  : false
                              }
                              onChange={handleInputChange}
                            />
                          ) : attr === "Dienkommentar" ? (
                            <input
                              name={attr}
                              type="text"
                              value={currentDienwunschEdit[attr]}
                              onChange={handleInputChange}
                            />
                          ) : (
                            <TagsInput
                              name={attr}
                              pretty={dienwuenschAttributes[attr].pretty}
                              tags={currentDienwunschEdit[attr]}
                              users={
                                objectAttr.includes(attr) ? allNames : false
                              }
                              messen={messeAttr.includes(attr) ? messen : false}
                              onChange={handleInputChange}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Dienwuensche;

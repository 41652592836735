import React, { useEffect, useState } from "react";
import userService from "../../services/user.service";
import CircleLoader from "react-spinners/CircleLoader";
import kalenderService from "../../services/kalender.service";
import moment from "moment";

const Messe = (props) => {
  const [posDict, setPosDict] = useState();
  const [tauschOpt, setTauschOpt] = useState();
  const [messe, setMesse] = useState();
  const [isEingetragen, setIsEingetragen] = useState(false);

  const retrieveTauschen = () => {
    kalenderService.getTauschen(props.messeId).then((res) => {
      let tauschs = {};
      for (let opt of res.data) {
        if (!tauschs.hasOwnProperty(opt.Datum)) {
          tauschs[opt.Datum] = [];
        }
        tauschs[opt.Datum].push(opt.Name);
      }
      const ordered = Object.keys(tauschs)
        .sort((a, b) => {
          return moment(a, "DD.MM.YYYY").isAfter(moment(b, "DD.MM.YYYY"))
            ? 1
            : -1;
        })
        .reduce((obj, key) => {
          obj[key] = tauschs[key];
          return obj;
        }, {});
      setTauschOpt(ordered);
    });
  };

  const retrievePosDict = () => {
    kalenderService.getPosDict(props.messeId).then((res) => {
      setPosDict(res.data);
    });
  };

  const retrieveMesse = () => {
    kalenderService.getMesse(props.messeId).then((res) => {
      setMesse(res.data);
    });
  };

  useEffect(() => {
    retrievePosDict();
    retrieveMesse();
  }, []);

  useEffect(() => {
    retrieveTauschen();
  }, [isEingetragen]);

  return (
    <div>
      {messe && posDict ? (
        <>
          <h4>
            {messe.Name +
              " am " +
              moment(messe.Datum).format("DD.MM.YYYY [um] HH:mm [Uhr]")}
          </h4>
          {messe.Positionen.map((pos) => (
            <div
              className="pos-wrapper"
              key={props.messeId + "" + pos.position}
            >
              <span className="pos-title">{posDict[pos.position]}: </span>
              {pos.messdiener.map((m, i) => {
                if (props.ownId === m && !isEingetragen) {
                  setIsEingetragen(true);
                }
                return (
                  props.namen.hasOwnProperty(m) && (
                    <span key={props.messeId + "" + pos.position + "" + m}>
                      <span
                        style={{
                          color: props.ownId === m ? "red" : "black",
                        }}
                      >
                        {props.namen[m].Vorname}{" "}
                        {props.namen[m].Name.substring(0, 1)}.{" "}
                      </span>
                      <span>
                        {`${i < pos.messdiener.length - 1 ? "- " : ""}`}
                      </span>
                    </span>
                  )
                );
              })}
            </div>
          ))}
          {isEingetragen && tauschOpt && (
            <>
              <h4>Die Tauschoptionen aus deiner Gruppenstunde:</h4>
              {Object.keys(tauschOpt).map((datum) => (
                <span key={props.messeId + "" + datum}>
                  <span className="pos-title">{datum}: </span>
                  {tauschOpt[datum].map((name, i) => (
                    <span key={name + datum + props.messeId}>
                      {name}
                      {`${i < tauschOpt[datum].length - 1 ? ", " : ""}`}{" "}
                    </span>
                  ))}
                  <br></br>
                </span>
              ))}
            </>
          )}
        </>
      ) : (
        <CircleLoader />
      )}
    </div>
  );
};

export default Messe;

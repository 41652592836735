import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import ReactTooltip from "react-tooltip";
import authService from "../../services/auth.service";
import LogoBasePage from "../home/LogoBasePage";

const ResetPassword = (props) => {
  const navigate = useNavigate();

  const [passwordState, setPasswordState] = useState({
    newPasswd: "",
    passRepeat: "",
  });
  const [passPatternMatch, setPassPatternMatch] = useState(false);
  const [message, setMessage] = useState("");

  const { token, userId } = useParams();

  const handlePasswordChange = (event) => {
    var state = { ...passwordState };
    state[event.target.name] = event.target.value;
    if (
      state["newPasswd"].match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      ) &&
      state["newPasswd"].length >= 8 &&
      state["newPasswd"].length <= 25 &&
      state["newPasswd"] === state["passRepeat"]
    ) {
      setPassPatternMatch(true);
    } else {
      setPassPatternMatch(false);
    }
    setPasswordState(state);
  };

  const resetPassword = (e) => {
    e.preventDefault();
    if (!passPatternMatch)
      return setMessage("Das Passwort entspricht nicht der Form");
    authService
      .resetPassword({
        password: passwordState.newPasswd,
        token: token,
        userId: userId,
      })
      .then((res) => {
        if (res.status !== 200)
          return setMessage("Es ist ein Fehler aufgetreten.");
        setMessage("Das Passwort wurde erfolgreich geändert!");
        navigate("/login");
      })
      .catch((err) => {
        setMessage("Es ist ein Fehler aufgetreten.")
      });
  };

  return (
    <LogoBasePage>
      <form onSubmit={resetPassword} className="login-form card">
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          Gib ein neues Passwort ein und merk es dir am besten;)
          <input
            type="password"
            name="newPasswd"
            style={{ width: "100%" }}
            placeholder="Neues Passwort"
            value={passwordState["newPasswd"]}
            onChange={handlePasswordChange}
            data-tip
            data-for={"tooltipPass"}
          />
          <input
            type="password"
            name="passRepeat"
            style={{ width: "100%" }}
            placeholder="Passwort wiederholen"
            value={passwordState["passRepeat"]}
            onChange={handlePasswordChange}
            data-tip
            data-for={"tooltipPass"}
          />
          <div
            style={{
              background: `${passPatternMatch ? "green" : "red"}`,
              height: "10px",
              width: "10px",
              borderRadius: "50%",
              position: "absolute",
              bottom: "22%",
              right: "5%",
            }}
          ></div>
          <ReactTooltip id="tooltipPass" place="top" effect="solid">
            Das neue Passwort muss aus mindestens einem Groß-, Kleinbuchstaben,
            Symbol und Zahl bestehen und zwischen 8 und 25 Zeichen lang sein.
          </ReactTooltip>
        </div>
        <input type="submit" className="btn" value="Speichern" />
      </form>
      {message}
    </LogoBasePage>
  );
};

export default ResetPassword;

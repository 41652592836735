import React, { useEffect, useState } from "react";
import adminService from "../services/admin.service";
import userService from "../services/user.service";
import TagsInput from "./base/TagsInput";
import moment from "moment";
import kalenderService from "../services/kalender.service";
import CircleLoader from "react-spinners/CircleLoader";
const Nachricht = (props) => {
  const [message, setMessage] = useState("");
  const [mplan, setMplan] = useState("");
  const [subject, setSubject] = useState("");
  const [rcps, setRCPS] = useState([]);
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState();
  const [mail, setMail] = useState(true);
  const [mms, setMMS] = useState(true);
  const [mplanAtt, setMplanAtt] = useState(false);
  const [isErinnerung, setIsErinnerung] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedEmail, setSelectedEmail] = useState("MAIL_INFO"); // Default selection
  const handleSelectChange = (event) => {
    setSelectedEmail(event.target.value);
  };
  const [messen, setMessen] = useState();

  const retrieveUsers = () => {
    userService.getAllNames().then((names) => {
      setUsers(
        names.data.sort((a, b) => {
          if (a.Name < b.Name) return -1;
          if (a.Name > b.Name) return 1;
          if (a.Vorname < b.Vorname) return -1;
          if (a.Vorname > b.Vorname) return 1;
          return 0;
        })
      );
      setLoading(false);
    });
  };

  const sendMessage = () => {
    setLoading(true);
    let formData = new FormData();
    if (files.length > 0) {
      for (let file of files) {
        formData.append("attachments", file);
      }
    }
    formData.append("sender", selectedEmail);
    formData.append("message", message);
    formData.append("subject", subject);
    formData.append("rcps", rcps);
    formData.append("mms", mms);
    formData.append("mplanAtt", mplanAtt);
    formData.append("isErinnerung", isErinnerung);
    adminService.nachricht(formData).then((res) => {
      setSubject("");
      setRCPS([]);
      setMessage("");
      setLoading(false);
    });
  };
  const copyMails = () => {
    setLoading(true);
    adminService.receiveMails({ rcps: rcps }).then((res) => {
      navigator.clipboard.writeText(res.data);
      setMessage("Mails wurden kopiert.");
      setLoading(false);
    });
  };

  const retrieveMessen = () => {
    kalenderService
      .getMessenMitDienern(
        moment().toISOString(),
        moment().add(3, "months").toISOString()
      )
      .then((res) => {
        setMessen(res.data);
      });
  };

  // Handler to set RCPS by Grustu
  const handleAddAllByGroup = (grustu) => {
    setRCPS(
      rcps.concat(
        users.filter((user) => user.Grustu === grustu).map((user) => user.id)
      )
    );
  };

  useEffect(() => {
    retrieveUsers();
    retrieveMessen();
  }, []);
  return (
    <div className="container">
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          <div className="card">
            <div>
              Empfänger:{" "}
              <TagsInput
                tags={rcps}
                users={users}
                onChange={(event) => setRCPS(event.target.value)}
              />
            </div>
            <div>
              <button
                className="btn"
                onClick={() => {
                  setRCPS(users.map((u) => u.id));
                }}
              >
                Alle hinzufügen
              </button>
              {[...new Set(users.map((user) => user.Grustu))]
                .sort((a, b) => a.localeCompare(b))
                .map((groupName) => (
                  <button
                    key={groupName}
                    className="btn"
                    onClick={() => handleAddAllByGroup(groupName)}
                  >
                    {groupName}
                  </button>
                ))}
              <button
                className="btn"
                onClick={() => {
                  setRCPS([]);
                }}
              >
                Alle löschen
              </button>
              Messe:
              <select
                onChange={(event) => {
                  let messe =
                    messen[
                      messen.findIndex((m) => m._id === event.target.value)
                    ];
                  let allMessdiener = messe.Positionen.reduce(
                    (acc, position) => {
                      return acc.concat(position.messdiener);
                    },
                    []
                  );
                  setRCPS(rcps.concat(allMessdiener));
                }}
              >
                <option>Messe auswählen</option>
                {messen &&
                  messen.map((m) => (
                    <option value={m._id}>
                      {m.Name} am {moment(m.Datum).format("DD.MM.yyyy")}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="card">
            <label for="email-select">Wähle den Absender:</label>
            <select
              id="email-select"
              value={selectedEmail}
              onChange={handleSelectChange}
            >
              <option value="MAIL_INFO">info@meheifa.de</option>
              <option value="MAIL_MMS">mms@meheifa.de</option>
            </select>
          </div>
          <div className="card">
            <p>Betreff</p>
            <input
              type="text"
              placeholder="Betreff"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
            />
          </div>
          <div className="card">
            <div>
              <p>Nachricht</p>
              <textarea
                name="message"
                placeholder="Nachricht"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </div>
            <div>
              Emojis auf Windows mit (Windows-Logo + .) und auf Mac mit (strg +
              cmd + Leertaste)
            </div>
            <div>
              Anhang
              <input
                type="file"
                multiple="multiple"
                file={files}
                onChange={(event) => setFiles(event.target.files)}
              />
            </div>
          </div>
          <table>
            <tr>
              <td>per MMS</td>
              <td>
                <input
                  type="checkbox"
                  checked={mms}
                  onChange={setMMS.bind(this, !mms)}
                />
              </td>
            </tr>
            <tr>
              <td>M-Plan als Anhang</td>
              <td>
                <input
                  type="checkbox"
                  checked={mplanAtt}
                  onChange={setMplanAtt.bind(this, !mplanAtt)}
                />
              </td>
            </tr>
            <tr>
              <td>Erinnerungsmail</td>
              <td>
                <input
                  disabled={!mail}
                  type="checkbox"
                  checked={isErinnerung}
                  onChange={setIsErinnerung.bind(this, !isErinnerung)}
                />
              </td>
            </tr>
          </table>
          <button class="btn" onClick={copyMails}>
            Mails kopieren
          </button>
          <button className="btn" onClick={sendMessage}>
            Senden
          </button>
        </>
      )}
    </div>
  );
};

export default Nachricht;

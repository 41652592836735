import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import adminService from "../../services/admin.service";
import Popup from "../base/Popup";
import CircleLoader from "react-spinners/CircleLoader";

const rowHeight = 23;
const cellWidth = 32;
const styles = {
  row: {
    height: rowHeight,
  },
  names: {
    width: 250,
    backgroundColor: "white !important",
    cursor: "default !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  cell: {
    width: cellWidth,
    height: rowHeight,
  },
  nextButton: {
    width: "2em",
    height: "2em",
  },
};

const Abwesenheiten = (props) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState();
  const [date, setDate] = useState(moment());
  const [days, setDays] = useState();
  const [filter, setFilter] = useState("");
  const [editAbwesenheit, setEditAbwesenheit] = useState(null);
  const [createAbwesenheit, setCreateAbwesenheit] = useState(null);

  const deleteAbwesenheit = (userIndex, id) => {
    var array = [...users[userIndex].Abwesenheit];
    array.splice(
      array.findIndex((a) => a._id === id),
      1
    );
    adminService
      .editUser(users[userIndex].id, {
        Abwesenheit: array,
      })
      .then(() => {
        retrieveUsers();
      });
  };

  const addAbwesenheit = (userIndex, ab) => {
    if (!userIndex || !ab) return;
    var array = [...users[userIndex].Abwesenheit];
    delete ab.userI;
    ab.Start.startOf("day");
    ab.Ende.endOf("day");

    let i = array.findIndex((a) => moment(a.Ende).diff(ab.Start) === -1);
    let j = array.findIndex((a) => moment(a.Start).diff(ab.Ende) === 1);
    if (i !== -1) {
      if (j !== -1) {
        array[i].Ende = array[j].Ende;
        array.splice(j, 1);
      } else {
        array[i].Ende = ab.Ende;
      }
    } else if (j !== -1) {
      array[j].Start = ab.Start;
    } else {
      array.push(ab);
    }

    adminService
      .editUser(users[userIndex].id, {
        Abwesenheit: array,
      })
      .then(() => {
        setCreateAbwesenheit(null);
        retrieveUsers();
      });
  };
  const changeAnwesenheit = (userIndex, datum) => {
    addAbwesenheit(userIndex, {
      Start: moment(datum),
      Ende: moment(datum),
      Grund: "",
    });
  };

  const YEARS = () => {
    const years = [];
    const dateStart = moment();
    const dateEnd = moment().add(10, "y");
    while (dateEnd.diff(dateStart, "years") >= 0) {
      years.push(dateStart.format("YYYY"));
      dateStart.add(1, "year");
    }
    return years;
  };

  const MONTHS = () => {
    const months = [];
    const dateStart = moment();
    const dateEnd = moment().add(12, "month");
    while (dateEnd.diff(dateStart, "months") >= 0) {
      months.push({
        name: dateStart.format("MMMM"),
        number: dateStart.format("MM"),
      });
      dateStart.add(1, "month");
    }
    return months;
  };

  const DAYS = () => {
    const days = [];
    const dateStart = moment(date);
    const dateEnd = moment(date).add(1, "months");
    while (dateEnd.diff(dateStart, "days") > 0) {
      days.push({
        number: dateStart.format("DD"),
        name: dateStart.format("dd"),
      });
      dateStart.add(1, "days");
    }
    return days;
  };
  const months = MONTHS();
  const years = YEARS();
  const retrieveUsers = () => {
    adminService.getAllAbwesenheiten().then((users) => {
      setUsers(users.data);
      setLoading(false);
    });
  };

  const generateDays = () => {
    setDays(DAYS());
  };

  useEffect(() => {
    retrieveUsers();
    setDate((d) => d.set("date", 1));
    setDays(DAYS());
  }, []);

  return (
    <div className="container full-width">
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          <div className="abw-admin-header">
            <div className="abw-admin-header-nav">
              <button
                className="btn-style"
                style={styles.nextButton}
                onClick={() => {
                  setDate(date.subtract(1, "month"));
                  generateDays();
                }}
              >
                {"<"}
              </button>
              <select
                className="btn-style"
                style={{ height: "2em" }}
                name="month"
                onChange={(event) => {
                  setDate(date.set("month", event.target.value - 1));
                  generateDays();
                }}
                value={date.format("MM")}
              >
                {months.map((month, i) => (
                  <option key={month.number + "" + i} value={month.number}>
                    {month.name}
                  </option>
                ))}
              </select>
              <select
                className="btn-style"
                name="year"
                style={{ height: "2em" }}
                onChange={(event) => {
                  setDate(date.set("year", event.target.value));
                  generateDays();
                }}
                value={date.format("YYYY")}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <button
                className="btn-style"
                style={styles.nextButton}
                onClick={() => {
                  setDate(date.add(1, "month"));
                  generateDays();
                }}
              >
                {">"}
              </button>
              <input
                type="text"
                placeholder="Filter nach Name"
                name="filter"
                value={filter}
                onChange={(event) => {
                  setFilter(event.target.value);
                }}
              ></input>
              <button
                className="btn"
                onClick={() => {
                  setCreateAbwesenheit({
                    Start: moment(),
                    Ende: moment(),
                    Grund: "",
                  });
                }}
              >
                Abwesenheit hinzufügen
              </button>
            </div>
            <div className="abw-admin-row">
              <div style={styles.names}>Name</div>
              {days &&
                days.map((day) => (
                  <div
                    key={day.number}
                    style={{ width: cellWidth }}
                    className={`${day.name === "So" ? "day-highlight" : ""}`}
                  >
                    {day.number + ", " + day.name}
                  </div>
                ))}
            </div>
          </div>
          <div className="abw-admin-table">
            {users &&
              users.map(
                (user, userIndex) =>
                  (user.Name.toLowerCase().startsWith(filter.toLowerCase()) ||
                    user.Vorname.toLowerCase().startsWith(
                      filter.toLowerCase()
                    ) ||
                    (
                      user.Vorname.toLowerCase() +
                      " " +
                      user.Name.toLowerCase()
                    ).startsWith(filter.toLowerCase())) && (
                    <div
                      className="abw-admin-row"
                      key={user.id}
                      style={{ ...styles.row }}
                    >
                      <div style={styles.names}>
                        {user.Name + ", " + user.Vorname}
                      </div>
                      {days &&
                        days.map((day, index) => (
                          <div
                            style={styles.cell}
                            key={user.id + date + day.number}
                            onClick={changeAnwesenheit.bind(
                              this,
                              userIndex,
                              date.format("YYYY-MM-") + day.number
                            )}
                          ></div>
                        ))}
                      {user.Abwesenheit.map((a) => {
                        let i = days
                          .map((day, i) => {
                            let m = moment()
                              .set("year", date.get("year"))
                              .set("month", date.get("month"))
                              .set("date", Number(day.number));
                            return m.isSameOrAfter(
                              moment(a.Start).startOf("day")
                            ) && m.isSameOrBefore(moment(a.Ende).endOf("day"))
                              ? i + 1
                              : "";
                          })
                          .filter(String);
                        return (
                          <a
                            key={a._id}
                            style={{
                              left:
                                styles.names.width + 1 + (i[0] - 1) * cellWidth,
                              position: "absolute",
                              backgroundColor: moment(a.Ende).diff(moment(a.Start)) > 1000 * 60 * 60 * 24 * 30 ? "red": "violet",
                              width: i.length * cellWidth - 1,
                              height: rowHeight,
                              top: 1,
                              cursor: "pointer",
                              overflow: "hidden",
                            }}
                            hidden={!(i.length > 0)}
                            onClick={
                              i.length > 1
                                ? setEditAbwesenheit.bind(this, {
                                    ...a,
                                    userI: userIndex,
                                  })
                                : deleteAbwesenheit.bind(this, userIndex, a._id)
                            }
                          >
                            {i.length > 7 && a.Grund}
                          </a>
                        );
                      })}
                    </div>
                  )
              )}
          </div>
          {editAbwesenheit && (
            <Popup
              hidden={!editAbwesenheit}
              onClose={setEditAbwesenheit.bind(this, null)}
            >
              <div>
                <h4>
                  {users[editAbwesenheit.userI].Vorname +
                    " " +
                    users[editAbwesenheit.userI].Name}
                </h4>
                <input
                  type="date"
                  name={"Start"}
                  value={moment(editAbwesenheit.Start).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setEditAbwesenheit({
                      ...editAbwesenheit,
                      Start: moment(e.target.value),
                    });
                  }}
                ></input>{" "}
                bis{" "}
                <input
                  type="date"
                  name={"Ende"}
                  value={moment(editAbwesenheit.Ende).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setEditAbwesenheit({
                      ...editAbwesenheit,
                      Ende: moment(e.target.value),
                    });
                  }}
                ></input>{" "}
                Grund :{" "}
                <input
                  type="text"
                  name="Grund"
                  value={editAbwesenheit.Grund}
                  onChange={(e) => {
                    setEditAbwesenheit({
                      ...editAbwesenheit,
                      Grund: e.target.value,
                    });
                  }}
                ></input>
                <button
                  onClick={() => {
                    deleteAbwesenheit(
                      editAbwesenheit.userI,
                      editAbwesenheit._id
                    );
                    setEditAbwesenheit(null);
                  }}
                >
                  Löschen
                </button>
              </div>
            </Popup>
          )}
          {createAbwesenheit && (
            <Popup
              hidden={!createAbwesenheit}
              onClose={setCreateAbwesenheit.bind(this, null)}
            >
              <div>
                <select
                  onChange={(event) =>
                    setCreateAbwesenheit({
                      ...createAbwesenheit,
                      userI: event.target.value,
                    })
                  }
                  value={createAbwesenheit.userI}
                >
                  <option value="">Auswählen</option>
                  {users.map((user, index) => (
                    <option key={index} value={index}>
                      {user.Vorname + " " + user.Name}
                    </option>
                  ))}
                </select>
                <input
                  type="date"
                  name={"Start"}
                  value={moment(createAbwesenheit.Start).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setCreateAbwesenheit({
                      ...createAbwesenheit,
                      Start: moment(e.target.value),
                    });
                  }}
                ></input>{" "}
                bis{" "}
                <input
                  type="date"
                  name={"Ende"}
                  value={moment(createAbwesenheit.Ende).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setCreateAbwesenheit({
                      ...createAbwesenheit,
                      Ende: moment(e.target.value),
                    });
                  }}
                ></input>{" "}
                Grund :{" "}
                <input
                  type="text"
                  name="Grund"
                  value={setCreateAbwesenheit.Grund}
                  onChange={(e) => {
                    setCreateAbwesenheit({
                      ...createAbwesenheit,
                      Grund: e.target.value,
                    });
                  }}
                ></input>
                <button
                  onClick={() => {
                    addAbwesenheit(createAbwesenheit.userI, createAbwesenheit);
                  }}
                >
                  Erstellen
                </button>
              </div>
            </Popup>
          )}
        </>
      )}
    </div>
  );
};

export default Abwesenheiten;

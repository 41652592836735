import React, { useEffect, useState } from "react";
import userService from "../../services/user.service";
import CircleLoader from "react-spinners/CircleLoader";
import kalenderService from "../../services/kalender.service";
import moment from "moment";
import "moment-easter";

const Bestenliste = (props) => {
  const [loading, setLoading] = useState(true);
  const [currentLeaderboard, setCurrentLeaderboard] = useState();
  const [leaderboards, setLeaderboards] = useState();
  const [nameDict, setNameDict] = useState();
  const [mfgDate, setMfgDate] = useState();
  const [nMessen, setNMessen] = useState();

  const retrieveNamen = () => {
    userService.getAllNames().then((res) => {
      res.data.sort((a, b) => {
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        if (a.Vorname < b.Vorname) return -1;
        if (a.Vorname > b.Vorname) return 1;
        return 0;
      });
      let names = [];
      for (let u of res.data) {
        names[u.id] = { Vorname: u.Vorname, Name: u.Name };
      }
      setNameDict(names);
    });
  };

  const retrieveDienscore = () => {
    kalenderService.getDienscore().then((res) => {
      const nMessen = res.data["nMessen"];
      delete res.data["nMessen"];
      let dienscore = Object.keys(res.data).map((key) => ({
        id: key,
        ...res.data[key],
      }));

      const userId = localStorage.getItem("user");
      let { anwesend, grustu } =
        dienscore[dienscore.findIndex((a) => a.id === userId)];
      if (grustu === "PLR") grustu = "LR";
      let dienscoreAlle = dienscore.sort((a, b) => {
        if (a.anwesend < b.anwesend) return 1;
        if (a.anwesend > b.anwesend) return -1;
        return 0;
      });

      let leaderboards = [
        {
          name: "Gesamtwertung",
          score: dienscoreAlle.slice(0, 10),
          platz: dienscoreAlle.findIndex((a) => a.anwesend === anwesend) + 1,
          length: dienscoreAlle.length,
        },
      ];

      let dienscoreGrustu = dienscoreAlle.filter((a) =>
        a.grustu.includes(grustu)
      );
      leaderboards.push({
        name: grustu + "-Wertung",
        score: dienscoreGrustu.slice(0, Math.min(dienscoreGrustu.length, 10)),
        platz: dienscoreGrustu.findIndex((a) => a.anwesend === anwesend) + 1,
        length: dienscoreGrustu.length,
      });

      let dienscoreTeilnehmer = dienscoreAlle.filter(
        (a) => !a.grustu.includes("LR")
      );
      leaderboards.push({
        name: "Teilnehmerwertung",
        score: dienscoreTeilnehmer.slice(
          0,
          Math.min(dienscoreTeilnehmer.length, 10)
        ),
        platz:
          grustu == "LR"
            ? -1
            : dienscoreTeilnehmer.findIndex((a) => a.anwesend === anwesend) + 1,
        length: dienscoreTeilnehmer.length,
      });
      leaderboards = leaderboards.reverse();
      let year = moment().year();
      if (moment().easter(year).add(59, "days").isAfter(moment())) year--;
      setMfgDate(moment().set("year", year).easter().add(59, "days").format("DD.MM.yyyy"));
      setNMessen(nMessen);
      setLeaderboards(leaderboards);
      setCurrentLeaderboard(leaderboards[0]);
      setLoading(false);
    });
  };

  useEffect(() => {
    retrieveDienscore();
    retrieveNamen();
  }, []);

  return (
    <div
      className="container full-width"
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      {loading ? (
        <CircleLoader />
      ) : (
        <div className="leaderboard-wrapper">
          {leaderboards && nameDict && (
            <div>
              <div className="tabs">
                {leaderboards.map((board) => (
                  <button
                    key={board.name}
                    className={`tab-button ${
                      board.name === currentLeaderboard.name ? "select" : ""
                    }`}
                    onClick={() => {
                      setCurrentLeaderboard(board);
                    }}
                  >
                    {board.name}
                  </button>
                ))}
              </div>
              {currentLeaderboard && (
                <>
                  <h3>{currentLeaderboard.name}</h3>
                  <table className="bestenliste-table">
                    <thead>
                      <tr>
                        <th>Platz</th>
                        <th>Name</th>
                        <th>Anzahl</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentLeaderboard.score.map((top, i) => (
                        <tr key={top.id + currentLeaderboard.name}>
                          <td>
                            {currentLeaderboard.score.findIndex(
                              (a) => a.anwesend === top.anwesend
                            ) + 1}
                          </td>
                          <td>
                            {nameDict[top.id].Vorname}{" "}
                            {nameDict[top.id].Name.substring(0, 1)}.
                          </td>
                          <td>{top.anwesend}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {currentLeaderboard.platz > 0 && (
                    <p>{`Du stehst auf dem ${currentLeaderboard.platz}. Platz von ${currentLeaderboard.length} Messdienerinnen und Messdienern in dieser Wertung!`}</p>
                  )}
                  <p>{`Die Bestenliste zeigt die Anzahl wie oft seit dem letzten MFG (${mfgDate}) gedient wurde. Es haben ${nMessen} Dientermine stattgefunden.`}</p>
              
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Bestenliste;

import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "../../functions/basicFunctions";
const TagsInput = (props) => {
  const [inputVal, setInputVal] = useState("");
  const [message, setMessage] = useState("")
  const handleInputChange = (event) => {
    setInputVal(event.target.value);
  };

  const addTags = (event) => {    
    if(props.tags.includes(event.target.value)) return;  
    let code = event.target.value.charCodeAt(event.target.value.length-1);
    if(props.name === "MMS") {
      if(event.target.value.startsWith("49")) event.target.value = "+" + event.target.value
      if(event.target.value.startsWith("0")) event.target.value = "+49" + event.target.value.substring(1)
    }
    if(event.target.value.trim() == "") return setInputVal("");
    if (
      ((event.target.type === "text" && [32].includes(code)) || event.code=="Enter" ||
        event.target.type != "text") &&
      event.target.value !== ""
    ) {
      props.onChange({
        target: {
          value: [
            ...props.tags,
            code === 32 ? event.target.value.trim() : event.target.value
          ],
          name: props.name,
        },
      });
      setInputVal("");
    }
  };

  const removeTags = (index) => {
    props.onChange({
      target: {
        value: [
          ...props.tags.filter((tag) => props.tags.indexOf(tag) !== index),
        ],
        name: props.name,
        old: props.name === "extraPositionen" ? props.tags[index] : undefined,
      },
    });
  };

  return (
    <div className="tags-input">
      <ul id="tags">
        {props.tags &&
          props.tags.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">
                {props.users
                  ? props.users.map((name) => {
                      if (name.id === tag)
                        return name.Name + ", " + name.Vorname;
                    })
                  : props.messen
                  ? props.messen.map((messe) => {
                      if (messe.id === tag)
                        return (
                          moment(messe.Datum).format("DD.MM.YY [um] hh:mm [Uhr]")
                        );
                    })
                  : props.posDict? props.posDict[tag] : props.date
                  ? moment(tag).format("DD.MM.YY") 
                  : tag}
              </span>
              <span
                className="tag-close-icon"
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </li>
          ))}
      </ul>
      {props.date ? (
        <>
          <input
            type="date"
            onChange={(e) =>
              addTags({
                target: { value: moment(e.target.value).format("YYYY-MM-DD") },
              })
            }
          />
        </>
      ) : props.users ? (
        <>
          <select onChange={(event) => addTags(event)} value={inputVal}>
            <option value="">Hinzufügen</option>
            {props.users.filter(u => !props.tags.includes(u.id) && (u.id !== localStorage.getItem('user') || hasPermission(['admin']))).map((user) => (
              <option key={user.id} value={user.id}>
                {user.Vorname +" "+ user.Name.charAt(0) + ". "}
              </option>
            ))}
          </select>
          
        </>
      ) : props.messen ? (
        <>
          <select onChange={(event) => addTags(event)} value={inputVal}>
            <option value="">Hinzufügen</option>
            {props.messen.map((messe) => (
              <option key={messe.id} value={messe.id}>
                {messe.Name +
                  " am " +
                  moment(messe.Datum).format("DD.MM. [um] HH:mm [Uhr]")}
              </option>
            ))}
          </select>
        </>
      ) : props.posDict ? (
        <>
          <select onChange={(event) => addTags(event)} value={inputVal}>
            <option value="">Hinzufügen</option>
            {Object.keys(props.posDict).map((pos) => (
              <option key={pos} value={pos}>
                {props.posDict[pos]}
              </option>
            ))}
          </select>
        </>
      ) :(
        <input
          type="text"
          onChange={handleInputChange}
          value={inputVal}
          onKeyUpCapture={(event) => addTags(event)}
          placeholder={"Leertaste = " + (props.pretty ?? props.name) + " hinzufügen"}
        />
      )}
      {message}
    </div>
  );
};

export default TagsInput;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { arraysEqual } from "../../functions/basicFunctions";
import TagsInput from "../base/TagsInput";

const DienumfrageMesse = ({
  messe,
  messeI,
  onEditEingesprungen,
  onChangeAnwesenheit,
  nameDict,
  posDict,
}) => {
  const [editUser, setEditUser] = useState([]);

  return (
    <div className="container full-width">
      <h3>
        {messe.Name} am {moment(messe.Datum).format("DD.MM.YYYY")} um{" "}
        {moment(messe.Datum).format("hh:mm")} Uhr:
      </h3>
      {messe.Positionen.map((pos, posI) => (
        <div key={messe.id + pos.position}>
          <p>{posDict[pos.position]}:</p>
          {pos.messdiener.map((m, mI) => (
            <>
              {m !== undefined && nameDict.hasOwnProperty(m) && (
                <div
                  className={`dienstat-btn-wrapper ${
                    arraysEqual(editUser, [messeI, posI, mI]) ? "active" : ""
                  }`}
                  key={messe.id + pos.position + m}
                >
                  <button
                    className="dienstat-btn"
                    onClick={() => {
                      setEditUser(
                        arraysEqual(editUser, [messeI, posI, mI])
                          ? []
                          : [messeI, posI, mI]
                      );
                    }}
                  >
                    {nameDict[m].Vorname} {nameDict[m].Name}
                  </button>
                  <div
                    className="dienstat-display"
                    style={{
                      fontSize: nameDict.hasOwnProperty(pos.anwesend[mI])
                        ? "0.8rem"
                        : "2rem",
                    }}
                  >
                    {pos.anwesend[mI] === "anwesend"
                      ? "✅"
                      : pos.anwesend[mI] === "fehlend"
                      ? "❌"
                      : pos.anwesend[mI] === "entschuldigt"
                      ? "🇪" 
                      : pos.anwesend[mI] === "eingetragen"
                      ? "❓"
                      : nameDict.hasOwnProperty(pos.anwesend[mI])
                      ? `i.V. ${nameDict[pos.anwesend[mI]].Vorname} ${
                          nameDict[pos.anwesend[mI]].Name
                        }`
                      : ""}
                  </div>
                  {editUser.length === 3 &&
                    editUser[0] === messeI &&
                    editUser[1] === posI &&
                    editUser[2] === mI && (
                      <div className={`dienstatPopup`}>
                        <button
                          style={{ backgroundColor: "white" }}
                          onClick={() => {
                            onChangeAnwesenheit(messeI, posI, mI, "anwesend");
                            setEditUser([]);
                          }}
                        >
                          ✅
                        </button>
                        <button
                          style={{ backgroundColor: "white" }}
                          onClick={() => {
                            onChangeAnwesenheit(messeI, posI, mI, "fehlend");
                            setEditUser([]);
                          }}
                        >
                          ❌
                        </button>
                        <button
                          style={{ backgroundColor: "white" }}
                          onClick={() => {
                            onChangeAnwesenheit(
                              messeI,
                              posI,
                              mI,
                              "entschuldigt"
                            );
                            setEditUser([]);
                          }}
                        >
                          🇪
                        </button>
                        <select
                          value={
                            !["entschuldigt", "fehlend", "anwesend"].includes(
                              pos.anwesend[mI]
                            )
                              ? pos.anwesend[mI]
                              : null
                          }
                          onChange={(event) => {
                            onChangeAnwesenheit(
                              messeI,
                              posI,
                              mI,
                              event.target.value
                            );
                            setEditUser([]);
                          }}
                        >
                          <option value="eingetragen">i.V.</option>
                          {Object.keys(nameDict).map((id) => (
                            <option
                              key={messe.id + pos.position + m + id}
                              value={id}
                            >
                              {nameDict[id].Name}, {nameDict[id].Vorname}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                </div>
              )}
            </>
          ))}
        </div>
      ))}
      Zufällig eingesprungen:
      <TagsInput
        users={Object.keys(nameDict).map((id) => ({
          ...nameDict[id],
          id: id,
        }))}
        tags={messe.Eingesprungen}
        onChange={(event) => {
          onEditEingesprungen(messeI, event.target.value);
        }}
      />
    </div>
  );
};

export default DienumfrageMesse;

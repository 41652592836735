import React, { useState, useRef } from "react";
import AuthService from "../../services/auth.service";
//import mixpanel from "../../constants/mixpanel";
import meheifaLogo from "../../assets/meheifa-logo.png";
import Popup from "../base/Popup";
import LogoBasePage from "./LogoBasePage";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPasswort] = useState("");
  const [loading, setLoading] = useState(false);
  const [inForgot, setInForgot] = useState(false);
  const [message, setMessage] = useState(false);

  const forgotPassword = () => {
    let data = { username: username };
    AuthService.forgotPassword(data)
      .then((res) => {
        setMessage(res.data);
        setInForgot(false);
      })
      .catch(() => {
        setMessage(
          "Der Benutzername ist uns nicht bekannt. Frag sonst einfach Fred."
        );
        setInForgot(false);
      });
  };

  const logIn = (event) => {
    event.preventDefault();
    setLoading(true);
    let data = {
      username: username,
      password: password,
    };
    AuthService.login(data)
      .then((data) => {
        if (data)
          return props.onLogin({
            firstLogin: data.data.firstLogin,
            oldPass: password,
          });
        setMessage(
          "Der Benutzername oder das Passwort ist falsch. Beachte: Dein Benutzername unterscheidet sich von der ursprünglichen Meheifa-Seite."
        );
        setLoading(false);
      })
      .catch((err) => {});
  };

  return (
    <LogoBasePage>
      <form onSubmit={logIn} className="login-form card">
        <div className="input-wrapper">
          <input
            type="text"
            placeholder="Benutzername"
            className="login-input"
            id="username"
            required
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            name="username"
          />
        </div>
        <div className="input-wrapper">
          <input
            type="password"
            placeholder="Passwort"
            className="login-input"
            id="password"
            required
            value={password}
            onChange={(event) => {
              setPasswort(event.target.value);
            }}
            name="password"
          />
        </div>
        <a style={{ cursor: "pointer" }} onClick={setInForgot.bind(this, true)}>
          Passwort vergessen?
        </a>
        {message && (
          <p style={{ textAlign: "center", width: "100%" }}>{message}</p>
        )}
        <input type="submit" className="btn" value="Einloggen" />
      </form>
      <Popup hidden={!inForgot} onClose={setInForgot.bind(this, false)}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p style={{ textAlign: "center" }}>
            Trage bitte deinen Benutzernamen ein,<br></br> damit wir dir einen
            Link zum Zurücksetzen deines Passworts zusenden können. Dieser ist vornameKleinGeschrieben_ErsterBuchstabeDesNachnamens. (z.B. Max Mustermann -> max_m)
          </p>
          <input
            type="text"
            placeholder="Benutzername"
            className="login-input"
            id="username"
            required
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            name="username"
          />
          <button className="btn" onClick={forgotPassword}>
            Senden{" "}
          </button>
        </div>
      </Popup>
    </LogoBasePage>
  );
};

export default Login;

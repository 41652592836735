import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import userService from "../../services/user.service";

const ChangePassword = (props) => {
  const [passwordState, setPasswordState] = useState({
    oldPasswd: props.pwd ?? "",
    newPasswd: "",
    passRepeat: "",
  });
  const [passPatternMatch, setPassPatternMatch] = useState(false);
  const [message, setMessage] = useState("");

  const handlePasswordChange = (event) => {
    var state = { ...passwordState };
    state[event.target.name] = event.target.value;
    if (
      state["newPasswd"].match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      ) &&
      state["newPasswd"].length >= 8 &&
      state["newPasswd"].length <= 25 &&
      state["newPasswd"] === state["passRepeat"]
    ) {
      setPassPatternMatch(true);
    } else {
      setPassPatternMatch(false);
    }
    setPasswordState(state);
  };

  const changePassword = (e) => {
    e.preventDefault();
    if (!passPatternMatch)
      return setMessage("Das Passwort entspricht nicht der Form");
    userService
      .changePassword({
        oldPasswd: passwordState.oldPasswd,
        newPasswd: passwordState.newPasswd,
      })
      .then(() => {
        setMessage("Passwort wurde geändert!");
      })
      .catch((err) => {
        setMessage("Das Passwort ist falsch!");
      });
  };

  return (
    <div className="container">
      <form onSubmit={changePassword}>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {!props.pwd && (
            <input
              type="password"
              name="oldPasswd"
              style={{ width: "100%" }}
              placeholder="Altes Passwort"
              value={passwordState["oldPasswd"]}
              onChange={handlePasswordChange}
            />
          )}
          <input
            type="password"
            name="newPasswd"
            style={{ width: "100%" }}
            placeholder="Neues Passwort"
            value={passwordState["newPasswd"]}
            onChange={handlePasswordChange}
            data-tip
            data-for={"tooltipPass"}
          />
          <input
            type="password"
            name="passRepeat"
            style={{ width: "100%" }}
            placeholder="Passwort wiederholen"
            value={passwordState["passRepeat"]}
            onChange={handlePasswordChange}
            data-tip
            data-for={"tooltipPass"}
          />
          <div
            style={{
              background: `${passPatternMatch ? "green" : "red"}`,
              height: "10px",
              width: "10px",
              borderRadius: "50%",
              position: "absolute",
              bottom: "22%",
              right: "5%",
            }}
          ></div>
          <ReactTooltip id="tooltipPass" place="top" effect="solid">
            Das neue Passwort muss aus mindestens einem Groß-, Kleinbuchstaben,
            Symbol und Zahl bestehen und zwischen 8 und 25 Zeichen lang sein.
          </ReactTooltip>
        </div>
        <input type="submit" className="btn" value="Speichern" />
      </form>
      {message}
    </div>
  );
};

export default ChangePassword;

import React, { useEffect, useState } from "react";
import kalenderService from "../../services/kalender.service";
import moment from "moment";

const SerienmesseElement = (props) => {
  const getText = () => {
    var text = "";
    if (props.messe.Series.Wochentag !== undefined) {
      var days = [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
      ];
      text = ", wöchentlich, am " + days[props.messe.Series.Wochentag];
    } else if (props.messe.Series.Osterbezug !== undefined) {
      text =
        ", jährlich, " +
        Math.abs(props.messe.Series.Osterbezug) +
        " Tage " +
        (props.messe.Series.Osterbezug >= 0 ? "nach" : "vor") +
        " Ostern";
    } else if (props.messe.Series.Datum !== undefined) {
      text = ", jedes Jahr am " + moment(props.messe.Series.Datum).format('DD.MM.') 
    }

    return props.messe.Name + text + " um " + props.messe.Uhrzeit + " Uhr";
  };

  return (
    <tr>
      <td>{getText()}</td>
      <td>
        <button className="btn" onClick={props.onEdit}>Bearbeiten</button>
      </td>
    </tr>
  );
};

export default SerienmesseElement;

import React, { useEffect, useState } from "react";
import kalenderService from "../../services/kalender.service";
import moment, { isMoment } from "moment";
import Popup from "../base/Popup";

const EditSerienmesse = (props) => {
  const [messe, setMesse] = useState(props.messe);
  const [posDict, setPosDict] = useState(null);
  const [confirmEdit, setConfirmEdit] = useState(false);

  const retrievePosDict = () => {
    kalenderService.getPosDict().then((res) => {
      setPosDict(res.data);
    });
  };

  const handleInputChange = (event) => {
    var state = { ...messe };
    state[event.target.name] = event.target.value;
    setMesse(state);
  };

  const changePosAmount = (event, key) => {
    var state = { ...messe };
    const index = state.Positionen.map((e) => e.position).indexOf(key);
    if (index == -1) {
      state.Positionen.push({ position: key, amount: event.target.value });
    } else {
      state.Positionen[index].amount = event.target.value;
    }
    setMesse(state);
  };
  const deleteSerienmesse = async () => {
    await kalenderService.deleteSerienmesse(messe._id);
    props.retrieveMessen();
  };

  const handleSeriesInput = (event) => {
    var state = { ...messe };
    if (Object.keys(state.Series).length > 1 && !state.Series[event.target.name]) {
      delete state.Series;
      state.Series = {};
    }
    state.Series[event.target.name] = event.target.value;
    setMesse(state);
  };

  const handleWhichToEditInput = (event) => {
    var state = { ...messe };    
    if (!event.target.name.startsWith(Object.keys(state.WhichToEdit)[0])) {      
      delete state.WhichToEdit;
      state.WhichToEdit = {};
    }
    if(event.target.name.startsWith("Zwischen")){
      if(!state.WhichToEdit["Zwischen"])state.WhichToEdit["Zwischen"] = [moment(),moment().add(1, "day")]
      state.WhichToEdit["Zwischen"][Number(event.target.name.substring(8))] = event.target.value;
    }else{

      state.WhichToEdit[event.target.name] = event.target.value;
    }
    setMesse(state);
  }

  var days = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ];

  const [seriesState, setSeriesState] = useState({});

  const [osterPos, setOsterPos] = useState(messe.Series.Osterbezug && messe.Series.Osterbezug>0);

  const changeSeriesState = (event) => {
    var state = { ...messe };
    delete state.Series;
    state.Series = {};
    state.Series[event.target.value] = 0;
    setMesse(state);
  };

  const changeWhichToEditState = (event) => {
    var state = { ...messe };
    delete state.WhichToEdit;
    state.WhichToEdit = {};
    let date = moment().format("YYYY-MM-DD");
    state.WhichToEdit[event.target.value] = event.target.value === "Zwischen" ? [date, date] : date;
    setMesse(state);
  };


  const onSubmit = async (event) => {
    event.preventDefault();
    if (messe.Series.Osterbezug !== undefined && !osterPos && messe.Series.Osterbezug > 0)
      messe.Series.Osterbezug *= -1;
    if (props.create) {
      await kalenderService.addSerienmesse({ ...messe });
    } else {
      await kalenderService.editSerienmesse(messe._id, { ...messe });
    }
    props.retrieveMessen();
  };

  useEffect(() => {
    retrievePosDict();
  }, []);

  return (
    <>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="Name"
          placeholder="Name"
          required
          value={messe["Name"]}
          onChange={handleInputChange}
        />
        <div>
          <table>
            <tbody>
              <tr>
                <th>
                  <input
                    id="wochentag"
                    value="Wochentag"
                    type="radio"
                    name="series"
                    onChange={changeSeriesState}
                    checked={messe.Series.Wochentag !== undefined}
                  />
                  <label htmlFor="Wochentag">wöchentlich am</label>
                </th>
                <th>
                  <select
                    name="Wochentag"
                    onChange={handleSeriesInput}
                    value={seriesState["Wochentag"]}
                  >
                    {days.map((d, i) => (
                      <option key={messe._id + "" + i} value={i}>
                        {d}
                      </option>
                    ))}
                  </select>
                </th>
              </tr>
              <tr>
                <th>
                  <input
                    id="wochentag"
                    value="Datum"
                    type="radio"
                    name="series"
                    onChange={changeSeriesState}
                    checked={messe.Series.Datum !== undefined}
                  />
                  <label htmlFor="Datum">jedes Jahr am</label>
                </th>
                <th>
                  <input
                    type="date"
                    name="Datum"
                    min={moment().year() + "-01-01"}
                    max={moment().year() + "-12-31"}
                    value={moment(messe.Series.Datum).format("YYYY-MM-DD")}
                    onChange={handleSeriesInput}
                  ></input>
                </th>
              </tr>
              <tr>
                <th>
                  <input
                    id="wochentag"
                    value="Osterbezug"
                    type="radio"
                    name="series"
                    onChange={changeSeriesState}
                    checked={messe.Series.Osterbezug !== undefined}
                  />
                  <label htmlFor="Osterbezug">Ostern:</label>
                </th>
                <th>
                  <input
                    min="0"
                    step="1"
                    name="Osterbezug"
                    type="number"
                    value={Math.abs(messe.Series["Osterbezug"])}
                    onChange={handleSeriesInput}
                  />{" "}
                  Tage{" "}
                  <span
                    style={{ border: "1px solid black", cursor: "pointer" }}
                    onClick={setOsterPos.bind(this, !osterPos)}
                  >
                    {osterPos ? "danach" : "davor"}
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
          um
          <input
            type="time"
            name="Uhrzeit"
            placeholder="Uhrzeit"
            value={messe["Uhrzeit"]}
            onChange={handleInputChange}
            required
          />{" "}
          Uhr
        </div>
        {posDict &&
          Object.keys(posDict).map((key) => (
            <div key={messe._id + "" + key}>
              <input
                min="0"
                step="1"
                type="number"
                value={
                  messe.Positionen.filter((pos) => {
                    return pos.position === key;
                  }).length > 0
                    ? messe.Positionen.filter((pos) => {
                        return pos.position === key;
                      })[0].amount
                    : 0
                }
                onChange={(event) => changePosAmount(event, key)}
              />{" "}
              {posDict[key]}
            </div>
          ))}
        {!props.create && (
          <>
            <p>
              Welche Messen sollen bearbeitet werden? (Alle Zuteilungen werden
              gelöscht.)
            </p>
            <table>
              <tbody>
                <tr>
                  <th>
                    <input
                      id="ab"
                      value="Ab"
                      type="radio"
                      name="ab"
                      onChange={changeWhichToEditState}
                      checked={messe.WhichToEdit.Ab !== undefined}
                    />
                    <label htmlFor="Wochentag">alles ab</label>
                  </th>
                  <th>
                  <input
                      type="date"
                      name="Ab"
                      value={moment(messe.WhichToEdit['Ab'] ?? moment()).format("YYYY-MM-DD")}
                      onChange={handleWhichToEditInput}
                    ></input>
                  </th>
                </tr>
                <tr>
                  <th>
                    <input
                      id="zwischen"
                      value="Zwischen"
                      type="radio"
                      name="zwischen"
                      onChange={changeWhichToEditState}
                      checked={messe.WhichToEdit.Zwischen !== undefined}
                    />
                    <label htmlFor="Datum">zwischen</label>
                  </th>
                  <th>
                    <input
                      type="date"
                      name="Zwischen0"
                      value={moment(messe.WhichToEdit.Zwischen? messe.WhichToEdit['Zwischen'][0] : moment()).format("YYYY-MM-DD")}
                      onChange={handleWhichToEditInput}
                    ></input>
                     {" und  "}
                    <input
                      type="date"
                      name="Zwischen1"
                      value={moment(messe.WhichToEdit.Zwischen? messe.WhichToEdit['Zwischen'][1] : moment().add("1", "day")).format("YYYY-MM-DD")}
                      onChange={handleWhichToEditInput}
                    ></input>
                  </th>
                </tr>                
              </tbody>
            </table>
          </>
        )}
        <input type="submit" value="Speichern" />
      </form>
      <Popup hidden={!confirmEdit} onClose={setConfirmEdit.bind(this, false)}>
        <></>
      </Popup>
      {!props.create && <button className="btn" onClick={deleteSerienmesse}>Löschen</button>}
    </>
  );
};

export default EditSerienmesse;

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./components/home/Login.js";
import Home from "./components/home/Home.js";
import AuthService from "./services/auth.service.js";
import { useNavigate, useLocation } from "react-router-dom";
import "./normalize.css";
import moment from "moment";
import localization from "moment/locale/de";
import Header from "./components/base/Header.js";
//CSS
import "./App.css";
import config from "./constants/config.js";
import { hasPermission } from "./functions/basicFunctions.js";
import Popup from "./components/base/Popup.js";
import ChangePassword from "./components/Nutzer/ChangePassword.js";
import ResetPassword from "./components/Nutzer/ResetPassword.js";

moment.updateLocale("de", localization);

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [firstLog, setFirstLog] = useState(false);
  const [oldPass, setOldPass] = useState("");

  const restrictedRoute = (path, element, permitted) => {
    return (
      <>
        {hasPermission(permitted) ? (
          <Route path={path} element={element} />
        ) : (
          <></>
        )}
      </>
    );
  };

  const logout = () => {
    setLoggedIn(false);
    AuthService.logout();
    navigate("/login");
    sessionStorage.setItem("loggedIn", false);
  };

  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("authToken"));

  const handleLogin = (data) => {
    setFirstLog(data.firstLogin);
    setOldPass(data.oldPass);
    navigate("/home");
    setLoggedIn(true);
  };

  useEffect(() => {
    async function checkLogin() {
      if (loggedIn && sessionStorage.getItem("loggedIn") === "true") {
        if (location.pathname === "/")
          navigate(
            localStorage.getItem("user") === "6317c352a5945112e0eac2cb"
              ? "/dienumfrage"
              : "/home"
          );
      } else if (loggedIn && !(sessionStorage.getItem("loggedIn") === "true")) {
        await AuthService.checkSession()
          .then((response) => {
            sessionStorage.setItem("loggedIn", true);
            if (location.pathname === "/login")
              navigate(
                localStorage.getItem("user") === "6317c352a5945112e0eac2cb"
                  ? "/dienumfrage"
                  : "/home"
              );
          })
          .catch((error) => {
            logout();
          });
      } else if (!location.pathname.startsWith("/passwordReset")) {
        navigate("/login");
      }
    }
    checkLogin(location);
  }, []);

  return (
    <div className="main bg-primary">
      {loggedIn && (
        <Header selected={location.pathname} handleLogout={logout} />
      )}

      <div
        className="body"
        key={localStorage.getItem("user")}
      >
        <Routes>
          {config.routes.map((route) => (
            <React.Fragment key={route}>
              {restrictedRoute(
                route.path,
                route.name === "Profil"
                  ? React.cloneElement(route.element, { handleLogout: logout })
                  : route.element,
                route.permitted
              )}
            </React.Fragment>
          ))}
          <Route
            path="login"
            element={loggedIn ? <></> : <Login onLogin={handleLogin} />}
          />
          <Route
            path="passwordReset/:token/:userId"
            element={<ResetPassword />}
          />
          <Route path="home" element={<Home handleLogout={logout} />} />
          <Route path="/" element={<Home handleLogout={logout} />} />
        </Routes>
      </div>
      <Popup onClose={setFirstLog.bind(this, false)} hidden={!firstLog}>
        <h3>Willkommen</h3>
        <p>
          auf der Seite für unseren Meheifa-Dienplan. Hier kann sich jeder mit
          seinem Account einloggen und selbst seine Abwesenheiten,
          Wunschdienpartner und Dienwünsche verwalten:) Es sind auch noch einige
          Dinge für die Zukunft geplant. Wenn ihr Ideen oder Anmerkungen habt,
          meldet euch gerne bei mir (Fred) :). Hier kannst du jetzt direkt dein
          neues Passwort einstellen :) Viel Spaß!
        </p>
        <ChangePassword
          pwd={oldPass.length > 0 ? oldPass : false}
          key={oldPass}
        />
      </Popup>
      <footer>
        <a href="https://meheifa.de/impressum">Impressum</a>
        <a href="https://meheifa.de/datenschutz">Datenschutz</a>
      </footer>
    </div>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import kalenderService from "../../services/kalender.service";
import moment from "moment";
import adminService from "../../services/admin.service";
import TagsInput from "../base/TagsInput";
import userService from "../../services/user.service";
import CircleLoader from "react-spinners/CircleLoader";

const Positionen = (props) => {
  const [posis, setPositionen] = useState(false);
  const [emptyPos, setEmptyPos] = useState({ Name: "", Grustus: [] });
  const [extraPos, setExtraPos] = useState({});
  const [allNames, setAllNames] = useState();
  const [loading, setLoading] = useState(true);

  const handleEmptyPos = (event) => {
    var obj = { ...emptyPos };
    obj[event.target.name] = event.target.value;
    setEmptyPos(obj);
  };

  const addPosition = () => {
    adminService.addPosition(emptyPos).then(() => {
      retrievePositions();
    });
  };

  const retrieveExtraPositions = () => {
    adminService.getExtraPositionen().then((res) => {
      setExtraPos(res.data);
    });
  };

  const retrievePositions = () => {
    adminService.getPositionen().then((res) => {
      userService.getAllNames().then((names) => {
        setAllNames(names.data);
        setPositionen(res.data);
        setLoading(false);
      });
    });
  };

  const handleInputChange = (event, i) => {
    if (event.target.name === "extraPositionen") {
      const userId = event.target.old
        ? event.target.old
        : event.target.value[event.target.value.length - 1];
      adminService.getUser(userId).then((res) => {
        var m = res.data;
        const index = m.extraPositionen.indexOf(posis[i].id);
        if (index > -1) {
          m.extraPositionen.splice(index, 1);
        } else {
          m.extraPositionen.push(posis[i].id);
        }
        adminService
          .editUser(userId, { extraPositionen: m.extraPositionen })
          .then(() => {
            retrieveExtraPositions();
          });
      });
    } else {
      var temp = [...posis];
      temp[i].Grustus = event.target.value;
      setPositionen(temp);
      adminService.updatePosition(temp[i].id, temp[i]).then(() => {
        retrievePositions();
      });
    }
  };

  useEffect(() => {
    retrievePositions();
    retrieveExtraPositions();
  }, []);
  return (
    <div className="container full-width">
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Grustus</th>
                <th>Sonstige</th>
              </tr>
            </thead>
            <tbody>
              {posis &&
                posis.map((pos, index) => (
                  <tr key={pos.id}>
                    <td>{pos.Name}</td>
                    <td>
                      <TagsInput
                        tags={pos.Grustus}
                        name="Grustus"
                        onChange={(event) => handleInputChange(event, index)}
                      />
                    </td>
                    <td>
                      <TagsInput
                        tags={extraPos[pos.id] ? extraPos[pos.id] : []}
                        name="extraPositionen"
                        users={allNames}
                        onChange={(event) => handleInputChange(event, index)}
                      />
                    </td>
                  </tr>
                ))}

              <tr>
                <td>
                  <input
                    type="text"
                    name="Name"
                    placeholder="Neue Position"
                    value={emptyPos.Name}
                    onChange={handleEmptyPos}
                  />
                </td>
                <td>
                  <TagsInput
                    tags={emptyPos.Grustus}
                    name="Grustus"
                    onChange={handleEmptyPos}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button className="btn" onClick={addPosition}>Hinzufügen</button>
        </>
      )}
    </div>
  );
};

export default Positionen;

import http from "../http-common";

const API_URL = "/kalender/";

class KalenderService {
  addMesse(data) {
    return http.post(API_URL + "addMesse", data);
  }

  getMessen(beginDate, endDate) {
    return http.get(API_URL + "messen/" + beginDate + "/" + endDate);
  }

  generateNew(beginDate, endDate, generate) {
    return http.get(
      API_URL + "messenAdmin/" + beginDate + "/" + endDate + "/" + generate
    );
  }
  getMessenMitDienern(beginDate, endDate) {
    return http.get(API_URL + "messenAvailable/" + beginDate + "/" + endDate);
  }

  getEinsatzStat(beginDate, endDate) {
    return http.get(API_URL + "/einsatzStatistik/" + beginDate + "/" + endDate);
  }

  createDienplan(beginDate, endDate) {
    return http.get(API_URL + "createDienplan/" + beginDate + "/" + endDate);
  }

  clearZuteilung(beginDate, endDate) {
    return http.get(API_URL + "clearZuteilung/" + beginDate + "/" + endDate);
  }

  getSerienmessen() {
    return http.get(API_URL + "serienmessen/");
  }
  changeMessdiener(messe, user, pos, index) {
    return http.get(
      API_URL +
        "changeMessdiener/" +
        messe +
        "/" +
        user +
        "/" +
        pos +
        "/" +
        index
    );
  }

  mplanAusgeben(beginDate, endDate) {
    return http.get(API_URL + "mplanAusgeben/" + beginDate + "/" + endDate);
  }

  addSerienmesse(messe) {
    return http.post(API_URL + "addSerienmesse/", messe);
  }
  editSerienmesse(id, data) {
    return http.post(API_URL + "editSerienmesse/" + id, data);
  }
  deleteSerienmesse(id) {
    return http.delete(API_URL + "serienmesse/" + id);
  }
  editMesse(id, data) {
    return http.post(API_URL + "editMesse/" + id, data);
  }
  getMesse(id) {
    return http.get(API_URL + "messe/" + id);
  }

  deleteMesse(id) {
    return http.delete(API_URL + "messe/" + id);
  }
  getPosDict() {
    return http.get(API_URL + "posDict/");
  }
  getDienwuensche(beginDate, endDate) {
    return http.get(API_URL + "dienwunschtabelle/" + beginDate + "/" + endDate);
  }
  getDienscore(beginDate, endDate) {
    return http.get(API_URL + "dienscore/" + beginDate + "/" + endDate);
  }
  
  getTauschen(messeId){
    return http.get(API_URL + "tauschen/" + messeId,)
  }
}

export default new KalenderService();

import http from "../http-common";
import config from "../constants/config.js";


const API_URL = "/users/";

class UserService {
  getAllNames() {
    return http.get(API_URL + "all");
  }

  getUser(){
    return http.get(API_URL + "own");
  }
  editUser(data){
    return http.post(API_URL + "edit", data)
  }
  editDienwuensche(data){
    return http.post(API_URL + "editDienwuensche", data)
  }
  changePassword(data){
    return http.post(API_URL + "changePassword", data)
  }
  
}

export default new UserService();

import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
//import mixpanel from "../../constants/mixpanel";
import meheifaLogo from "../../assets/meheifa-logo.png";

const LogoBasePage = (props) => {
  
  const navigate = useNavigate();

  return (
    <div className="container">
      <img
        src={meheifaLogo}
        style={{ cursor:"pointer", width: "100%", margin: "6rem 0", padding: "0 3rem" }}
        alt="logo"
        onClick={() => { 
          navigate("/home");
        }}
      />
      {props.children}
    </div>
  );
};

export default LogoBasePage;

exports.arrayAttr = [
  "Mail_Haupt",
  "Mail_Erinnerung",
  "MMS",
  "Geschwister",
  "Dienpartner",
  "Abwesenheit",
  "Dienwunsch",
  "extraPositionen"
];
exports.objectAttr = ["Geschwister", "Dienpartner"];
exports.posAttr = ['extraPositionen'];
exports.messeAttr = ["Dienwunsch"];

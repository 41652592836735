import http from "../http-common";
import jwt_decode from "jwt-decode";
import authHeader from "./auth-header";
const API_URL = "/auth/";

class AuthService {
  forgotPassword(data) {
    return http.post(API_URL + "forgotPassword", data);
  }

  resetPassword(data){
    return http.post(API_URL + "resetPassword", data)
  }

  login(data) {
    return http
      .post(API_URL + "login", data)
      .then((response) => {
        if (response.status === 200) {
          var token = JSON.stringify(response.data.token);
          var refreshToken = response.data.refreshToken;
          var decoded = jwt_decode(token);
          localStorage.setItem("user", decoded._id);
          localStorage.setItem("roles", decoded.roles ?? "");
          localStorage.setItem("authToken", token);
          localStorage.setItem("refreshToken", refreshToken);
        }
        return response;
      })
      .catch((error) => {});
  }

  logout() {
    return http
      .post(API_URL + "logout", {
        refreshToken: localStorage.getItem("refreshToken"),
      })
      .then((res) => {
        localStorage.removeItem("user");
        localStorage.removeItem("roles");
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
      });
  }

  checkSession() {
    return http.get(API_URL + "check", { headers: authHeader() });
  }

  register(data) {
    return http.post(API_URL + "register", data);
  }
}

export default new AuthService();

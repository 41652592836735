import http from "../http-common";

const API_URL = "/admin/";

class AdminServer {
  getUsers(first, last) {
    return http.get(API_URL + "users/" + first + "/" + last);
  }

  createUser(data) {
    return http.post(API_URL + "createUser/", data);
  }

  createMultipleUsers(data) {
    return http.post(API_URL + "createMultipleUsers/", data);
  }

  nachricht(data) {
    return http.post(API_URL + "nachricht/", data, {
      headers: { "Content-type": "multipart/form-data" },
    });
  }

  receiveMails(data) {
    return http.post(API_URL + "receiveMails/", data);
  }

  getUser(id) {
    return http.get(API_URL + "user/" + id);
  }

  tabelleAusgeben() {
    return http.get(API_URL + "adressListeAusgeben");
  }
  dienstatistikAusgeben(beginDate, endDate) {
    return http.get(API_URL + "dienstatistikAusgeben/" + beginDate + "/" + endDate);
  }

  editUser(id, data) {
    return http.post(API_URL + "editUser/" + id, data);
  }

  deleteUser(id) {
    return http.get(API_URL + "deleteUser/" + id);
  }

  activateUser(id) {
    return http.get(API_URL + "activateUser/" + id);
  }

  getPositionen() {
    return http.get(API_URL + "positionen/");
  }

  getAllAbwesenheiten() {
    return http.get(API_URL + "abwesenheiten/");
  }

  removePosition(id) {
    return http.get(API_URL + "removePosition/" + id);
  }

  addPosition(data) {
    return http.post(API_URL + "addPosition/", data);
  }
  updatePosition(id, data) {
    return http.post(API_URL + "updatePosition/" + id, data);
  }
  getExtraPositionen() {
    return http.get(API_URL + "extraPositionen/");
  }
  getRoles() {
    return http.get(API_URL + "roles/");
  }
  addRole(data) {
    return http.post(API_URL + "addRole/", data);
  }
  updateRole(name, value) {
    return http.post(API_URL + "updateRole/", {
      name: name,
      messdiener: value,
    });
  }
  resetPasswordAdmin(userId) {
    return http.get(API_URL + "resetPasswordAdmin/", {params: {userId: userId}})
  }
  initiateWhatsapp(userId) {
    return http.get(API_URL + "initiateWhatsapp/", {params: {userId: userId}})
  }
}

export default new AdminServer();

import React, { useEffect, useState } from "react";
import kalenderService from "../../services/kalender.service";
import moment, { isMoment } from "moment";
import Popup from "../base/Popup";

const EditMesse = (props) => {
  const [messe, setMesse] = useState(null);
  const [posDict, setPosDict] = useState(null);
  const [confirmEdit, setConfirmEdit] = useState(false);

  const retrieveMesse = () => {
    kalenderService.getMesse(props.messeId).then((messe) => {
      let m = messe.data;
      m.Positionen = m.Positionen.map((position) => {
        return { ...position, amount: position.messdiener.length };
      });
      setMesse(messe.data);
    });
  };

  const retrievePosDict = () => {
    kalenderService.getPosDict().then((res) => {
      setPosDict(res.data);
      if (props.create) {
        let posis = [];
        for (let key of Object.keys(res.data)) {
          posis.push({ position: key, amount: 0 });
        }
        let initMesse = props.create;
        initMesse.Positionen = posis;
        setMesse(initMesse);
      }
    });
  };

  const handleInputChange = (event) => {
    var state = { ...messe };
    state[event.target.name] = event.target.value;
    setMesse(state);
  };

  const changePosAmount = (event, key) => {
    var state = { ...messe };
    const index = state.Positionen.findIndex((p) => p.position === key);
    state.Positionen[index].amount = Number(event.target.value);
    setMesse(state);
  };
  const deleteMesse = async () => {
    await kalenderService.deleteMesse(messe.id);
    props.onClose();
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (props.create) {
      await kalenderService.addMesse(messe);
      props.onClose();
    } else {
      await kalenderService.editMesse(messe.id, { ...messe });
      retrieveMesse();
    }
  };

  useEffect(() => {
    retrievePosDict();
    if (!props.create) retrieveMesse();
  }, []);

  return (
    <>
      {messe && (
        <form className="edit-messe" onSubmit={onSubmit}>
          <input
          style= {{fontWeight: "bold"}}
            type="text"
            name="Name"
            placeholder="Name"
            required
            value={messe["Name"]}
            onChange={handleInputChange}
          />
          <input
            type="datetime-local"
            name="Datum"
            value={moment(messe.Datum).format("YYYY-MM-DDTHH:mm")}
            onChange={handleInputChange}
          ></input>
          {posDict &&
            Object.keys(posDict).map((key) => (
              <div key={messe._id + "" + key}>
                {posDict[key]}
                <input
                  min="0"
                  step="1"
                  type="number"
                  value={
                    messe.Positionen.filter((pos) => {
                      return pos.position === key;
                    }).length > 0
                      ? messe.Positionen.filter((pos) => {
                          return pos.position === key;
                        })[0].amount
                      : 0
                  }
                  onChange={(event) => changePosAmount(event, key)}
                />{" "}
              </div>
            ))}
          <input
            className="btn"
            type="submit"
            value={`${props.create ? "Erstellen" : "Speichern"}`}
          />
        </form>
      )}
      <Popup hidden={!confirmEdit} onClose={setConfirmEdit.bind(this, false)}>
        <></>
      </Popup>
      {!props.create && (
        <button className="btn" onClick={deleteMesse}>
          Löschen
        </button>
      )}
    </>
  );
};

export default EditMesse;

import PropTypes from "prop-types";

import React, { useEffect, useRef, useState } from "react";

const Popup = (props) => {
  const ref = useRef();

  useEffect(() => {
    if (!props.hidden) {
    } else {
    }
  }, [props.hidden]);
  return (
    <div className="popup-wrapper" hidden={props.hidden} ref={ref}>
      <div className="popup-content">
        <button className="popup-close-button" onClick={props.onClose}>
          X
        </button>
        {!props.hidden && props.children}
      </div>
    </div>
  );
};

Popup.propTypes = {
  onClose: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
};

export default Popup;

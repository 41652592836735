import moment from "moment";
import "moment-easter"
import React, { useEffect, useState } from "react";
import CircleLoader from "react-spinners/CircleLoader";
import { arraysEqual, b64toBlob, saveBlob } from "../functions/basicFunctions";
import adminService from "../services/admin.service";
import kalenderService from "../services/kalender.service";
import userService from "../services/user.service";
import TagsInput from "./base/TagsInput";

const Dienstatistik = (props) => {
  const [loading, setLoading] = useState(true);
  const [nameDict, setNameDict] = useState();
  const [dienScore, setDienScore] = useState();
  const [sortBy, setSortBy] = useState({ key: "anwesend", inverse: true });
  const [nMessen, setNMessen] = useState();

  const anwesendAttributes = ["anwesend", "eingeteilt", "iV", "eingesprungen"];
  const fehlAttributes = ["fehlend", "entschuldigt", "unentschuldigt"];
  const getDates = () => {
    // Option 1: Date of Wednesday before Corpus Christi
    const currentYear = moment().year();
    const easterDate = moment().easter(currentYear)
    const corpusChristiDate = easterDate.clone().add(60, "days");
    let mfg = corpusChristiDate
      .clone()
      .subtract(3, "days")
      .isoWeekday(3);
    if (moment().diff(mfg, "days") < 0) {
      mfg.subtract(1, "year");
    }
    
    const firstSundayOfAdvent = moment()
      .year(currentYear)
      .month(10)
      .date(30)
      .isoWeekday(7);
    const christ_koenig = firstSundayOfAdvent
      .clone()
      .subtract(1, "week");
    if (moment().diff(christ_koenig, "days") < 0) {
      christ_koenig.subtract(1, "year");
    }
    const dates = moment().diff(mfg) < moment().diff(christ_koenig) ? [mfg, christ_koenig.add(1, 'year')] :[christ_koenig, mfg.add(1, 'year')]
    return dates
  }
  const [beginDate, setBeginDate] = useState(
    getDates()[0].format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    getDates()[1].format("YYYY-MM-DD")
  );
  

  const retrieveDienscore = () => {
    kalenderService.getDienscore(beginDate, endDate).then((res) => {
      const nMessen = res.data["nMessen"];
      delete res.data["nMessen"];
      setNMessen(nMessen);
      let dienscore = Object.keys(res.data).map((key) => ({
        id: key,
        ...res.data[key],
      }));
      setDienScore(sort(dienscore));
    });
  };

  const retrieveNamen = () => {
    userService.getAllNames().then((res) => {
      res.data.sort((a, b) => {
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        if (a.Vorname < b.Vorname) return -1;
        if (a.Vorname > b.Vorname) return 1;
        return 0;
      });
      let names = [];
      for (let u of res.data) {
        names[u.id] = { Vorname: u.Vorname, Name: u.Name };
      }
      setNameDict(names);
    });
  };

  const sort = (us) => {
    const { key, inverse } = sortBy;
    return us.sort((a, b) => {
      if (a[key] === null && b[key] !== null) return inverse ? -1 : 1;
      if (a[key] !== null && b[key] === null) return inverse ? 1 : -1;
      if (a[key] < b[key]) return inverse ? 1 : -1;
      if (a[key] > b[key]) return inverse ? -1 : 1;
      return 0;
    });
  };

  const tabelleAusgebenClicked = () => {
    adminService.dienstatistikAusgeben(beginDate, endDate).then((res) => {
      saveBlob(
        b64toBlob(res.data.blob, res.headers["content-type"]),
        `Dienstatistik_${moment().format("MM_YY")}.xlsx`
      );
    });
  };

  useEffect(() => {
    retrieveDienscore();
    retrieveNamen();
  }, [sortBy, beginDate, endDate]);

  return (
    <div className="container full-width">
      {!(nameDict && dienScore) ? (
        <CircleLoader />
      ) : (
        <>
          <button class="btn" onClick={tabelleAusgebenClicked}>
            Tabelle ausgeben
          </button>
          Messen vom
            <input
              type="date"
              onChange={(event) => {
                setBeginDate(event.target.value);
              }}
              value={beginDate}
            />
            bis
            <input
              type="date"
              onChange={(event) => {
                setEndDate(event.target.value);
              }}
              value={endDate}
            />
            ({nMessen} mögliche Messen.)
          <table>
            <thead>
              <tr>
                {[
                  "Name",
                  "grustu",
                  ...anwesendAttributes,
                  ...fehlAttributes,
                ].map((key) => (
                  <th
                    key={key}
                    onClick={() => {
                      let s = { ...sortBy };
                      s.inverse = s.key === key ? !s.inverse : false;
                      s.key = key;
                      setSortBy(s);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dienScore.map((user, index) => (
                <tr
                  key={user.id}
                  className={`table-row ${index % 2 ? "" : "table-alt"} ${
                    user.Inaktiv === true ? "inactive-user" : ""
                  }`}
                >
                  <td>
                    {nameDict[user.id].Vorname} {nameDict[user.id].Name}
                  </td>
                  <td>{user.grustu}</td>
                  {anwesendAttributes.map((key) => (
                    <td key={user.id + key}>{user[key]}</td>
                  ))}
                  {fehlAttributes.map((key) => (
                    <td key={user.id + key}>{user[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default Dienstatistik;

import React, { useEffect, useState } from "react";
import adminService from "../../services/admin.service";
import moment from "moment";
import TagsInput from "../base/TagsInput";
import userService from "../../services/user.service";
import kalenderService from "../../services/kalender.service";
import {
  arrayAttr,
  messeAttr,
  objectAttr,
  posAttr,
} from "../../constants/userAttributes";
import EditAbwesenheitProfile from "./EditAbwesenheitProfile";
import CircleLoader from "react-spinners/CircleLoader";
const EditNutzerAdmin = (props) => {
  const requiredAttributes = {
    Vorname: { pretty: "Vorname", desc: "" },
    Name: { pretty: "Nachname", desc: "" },
    Grustu: { pretty: "Gruppenstunde", desc: "" },
  };
  const optionalAttributes = {
    Abwesenheit: { pretty: "Abwesenheiten", desc: "" },
    Mail_Haupt: { pretty: "Hauptmail", desc: "" },
    MMS: { pretty: "MMS", desc: "An diese Nummern werden deine MMS versandt." },
    Adresse: { pretty: "Adresse", desc: "" },
    Mail_Erinnerung: { pretty: "Erinnerungsmail", desc: "" },
    Geschwister: { pretty: "Geschwister", desc: "" },
    Dienpartner: {
      pretty: "Dienpartner",
      desc: "Es wird versucht, dass du mit mindestens einem deiner Dienpartner aufgestellt wirst",
    },
    Dienwunsch: { pretty: "Dienwünsche", desc: "" },
    KeinWeihrauch: {
      pretty: "Weihrauch vermeiden",
      desc: "Nur, wenn es wirklich GAR nicht mit Weihrauch geht :)",
    },
    extraPositionen: { pretty: "Extra-Positionen" },
  };

  const [loading, setLoading] = useState(true);

  const [inputState, setInputState] = useState(false);
  const [messen, setMessen] = useState();
  const [allNames, setAllNames] = useState();
  const [posDict, setPosDict] = useState();

  const handleInputChange = (event) => {
    var state = { ...inputState };
    state[event.target.name] =
      event.target.name === "KeinWeihrauch"
        ? !state.KeinWeihrauch
        : event.target.value;
    setInputState(state);
  };

  const retrievePosDict = () => {
    kalenderService.getPosDict().then((res) => {
      setPosDict(res.data);
    });
  };

  const passwordResetClicked = () => {
    adminService.resetPasswordAdmin(props.userId).then((res) => {
      props.onEdit();
    })
  }

  const initiateWhatsappMMS = () => {
    adminService.initiateWhatsapp(props.userId).then((res) => {
      props.onEdit();
    })
  }

  const retrieveUser = () => {
    adminService.getUser(props.userId).then((res) => {
      userService.getAllNames().then((names) => {
        kalenderService
          .getMessen(
            moment().format("YYYY-MM-DD"),
            moment().add(4, "months").format("YYYY-MM-DD")
          )
          .then((messen) => {
            setAllNames(
              names.data.sort((a, b) => {
                if (a.Name < b.Name) return -1;
                if (a.Name > b.Name) return 1;
                if (a.Vorname < b.Vorname) return -1;
                if (a.Vorname > b.Vorname) return 1;
                return 0;
              })
            );
            setMessen(messen.data);
            setInputState(res.data);
            setLoading(false);
          });
      });
    });
  };

  const deleteUser = () => {
    adminService.deleteUser(props.userId).then(() => {
      props.onEdit();
    });
  };

  const activateUser = () => {
    adminService.activateUser(props.userId).then(() => {
      props.onEdit();
    });
  };

  const editUser = (event) => {
    event.preventDefault();
    adminService.editUser(props.userId, inputState).then((res) => {
      props.onEdit();
    });
  };

  useEffect(() => {
    retrievePosDict();
    retrieveUser();
  }, []);

  return (
    <div className="edit-nutzer-admin">
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          {inputState && (
            <form onSubmit={editUser}>
              <table>
                <tbody>
                  {Object.keys(requiredAttributes).map((attr) => (
                    <tr key={attr}>
                      <td>{requiredAttributes[attr].pretty}</td>
                      <td>
                        <input
                          type="text"
                          name={attr}
                          value={inputState[attr]}
                          placeholder={requiredAttributes[attr].pretty}
                          onChange={handleInputChange}
                          required
                        />
                      </td>
                    </tr>
                  ))}
                  {Object.keys(optionalAttributes).map((attr) => (
                    <tr key={attr}>
                      <td>{optionalAttributes[attr].pretty}</td>
                      <td>
                        {arrayAttr.includes(attr) ? (
                          attr === "Abwesenheit" ? (
                            <EditAbwesenheitProfile
                              abwesenheit={inputState[attr]}
                              onChange={handleInputChange}
                            />
                          ) : (
                            <TagsInput
                              name={attr}
                              tags={inputState[attr]}
                              users={
                                objectAttr.includes(attr) ? allNames : false
                              }
                              posDict={posAttr.includes(attr) ? posDict : false}
                              messen={messeAttr.includes(attr) ? messen : false}
                              onChange={handleInputChange}
                            />
                          )
                        ) : attr === "KeinWeihrauch" ? (
                          <input
                            name={attr}
                            type="checkbox"
                            checked={inputState[attr] === true ? true : false}
                            onChange={handleInputChange}
                          />
                        ) : (
                          <input
                            type="text"
                            name={attr}
                            value={inputState[attr]}
                            placeholder={optionalAttributes[attr].pretty}
                            onChange={handleInputChange}
                            key={attr}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>Geburtstag</td>
                    <td>
                      <input
                        type="date"
                        name="Geburtstag"
                        value={moment(inputState["Geburtstag"]).format(
                          "yyyy-MM-DD"
                        )}
                        placeholder="Geburtstag"
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Inaktiv</td>
                    <td>
                      <input
                        type="checkbox"
                        name="Inaktiv"
                        checked={
                          inputState["Inaktiv"] === "true" ||
                          inputState["Inaktiv"] === true
                            ? true
                            : false
                        }
                        onChange={() => {
                          let s = { ...inputState };
                          s["Inaktiv"] =
                            s["Inaktiv"] === "false" ? "true" : "false";
                          setInputState(s);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <input className="btn" type="submit" value="Abschicken" />
            </form>
          )}
          {!inputState.Username && (
            <button
              className="btn"
              onClick={() => {
                if (
                  window.confirm(
                    `Bist du dir sicher, dass du ${inputState.Vorname} ${inputState.Name} aktivieren möchtest?`
                  )
                )
                  activateUser();
              }}
            >
              Nutzer aktivieren
            </button>
          )}
          <button className="btn" onClick={() => {
                if (
                  window.confirm(
                    `Bist du dir sicher, dass du ${inputState.Vorname} ${inputState.Name} löschen möchtest?`
                  )
                )
                  deleteUser();
              }}>
            Nutzer löschen
          </button>
          <button className="btn" onClick={() => {
                if (
                  window.confirm(
                    `Bist du dir sicher, dass du ${inputState.Vorname} ${inputState.Name} Passwort zurücksetzen möchtest?`
                  )
                )
                  passwordResetClicked();
              }}>
            Passwort zurücksetzen
          </button>
          <button className="btn" onClick={initiateWhatsappMMS}>
            Für MMS (WA) anfragen
          </button>
        </>
      )}
    </div>
  );
};

export default EditNutzerAdmin;

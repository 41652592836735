import moment from "moment";
import React, { useEffect, useState } from "react";
import CircleLoader from "react-spinners/CircleLoader";
import kalenderService from "../../services/kalender.service";
import userService from "../../services/user.service";
import DienumfrageMesse from "./DienumfrageMesse";

const Dienumfrage = (props) => {
  const [loading, setLoading] = useState(true);
  const [messen, setMessen] = useState();
  const [posDict, setPosDict] = useState();
  const [nameDict, setNameDict] = useState();

  const retrieveMessen = () => {
    kalenderService
      .generateNew(
        moment().subtract(3, "days").toDate(),
        moment().add(4, "days").toDate()
      )
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < res.data[i].Positionen.length; j++) {
            if (
              !res.data[i].Positionen[j].hasOwnProperty("anwesend") ||
              res.data[i].Positionen[j].anwesend.length === 0
            ) {
              res.data[i].Positionen[j].anwesend = new Array(
                res.data[i].Positionen[j].messdiener.length
              );
              res.data[i].Positionen[j].anwesend.fill("eingetragen");
            }
          }
        }
        setMessen(res.data);
        setLoading(false);
      });
  };

  const editEingesprungen = (messeI, eingesprungen) => {
    let pMessen = [...messen];
    pMessen[messeI].Eingesprungen = eingesprungen;
    editMesse(pMessen[messeI].id, pMessen[messeI]);
  };

  const changeAnwesenheit = (messeI, posI, mI, type) => {
    let pMessen = [...messen];
    pMessen[messeI].Positionen[posI].anwesend[mI] = type;
    editMesse(pMessen[messeI].id, pMessen[messeI]);
  };

  const editMesse = (id, data) => {
    kalenderService.editMesse(id, data).then((res) => {
      retrieveMessen();
    });
  };

  const retrievePosDict = () => {
    kalenderService.getPosDict().then((res) => {
      setPosDict(res.data);
    });
  };

  const retrieveNamen = () => {
    userService.getAllNames().then((res) => {
      res.data.sort((a, b) => {
        if (a.Name < b.Name) return -1;
        if (a.Name > b.Name) return 1;
        if (a.Vorname < b.Vorname) return -1;
        if (a.Vorname > b.Vorname) return 1;
        return 0;
      });
      let names = [];
      for (let u of res.data) {
        names[u.id] = { Vorname: u.Vorname, Name: u.Name };
      }
      setNameDict(names);
    });
  };

  useEffect(() => {
    retrieveMessen();
    retrievePosDict();
    retrieveNamen();
  }, []);
  return (
    <div className="container full-width">
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          {messen && posDict && nameDict && (
            <>
              {messen.map((messe, messeI) => (
                <DienumfrageMesse
                  messe={messe}
                  messeI={messeI}
                  onEditEingesprungen={editEingesprungen}
                  onChangeAnwesenheit={changeAnwesenheit}
                  nameDict={nameDict}
                  posDict={posDict}
                  key={messe.id}
                />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Dienumfrage;

import React, { useEffect, useState } from "react";
import kalenderService from "../../services/kalender.service";
import moment from "moment";
import Popup from "../base/Popup";
import EditMesse from "./EditMesse";
import CircleLoader from "react-spinners/CircleLoader";

const Kalender = (props) => {
  const [messen, setMessen] = useState([]);
  const [endDate, setEndDate] = useState(moment().add(3, "months")); //
  const [beginDate, setBeginDate] = useState(moment());
  const [days, setDays] = useState([]);
  const [editMesse, setEditMesse] = useState(false);
  const [inCreate, setInCreate] = useState(false);
  const [loading, setLoading] = useState(true);

  const retrieveMessen = (generate = false) => {
    kalenderService
      .generateNew(
        beginDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        generate ? 1 : 0
      )
      .then((res) => {
        setMessen(res.data);
        setLoading(false);
      });
  };

  const loadMore = () => {
    endDate.add(1, "months");
    retrieveMessen();
    generateDays();
  };

  const loadBefore = () => {
    beginDate.subtract(1, "months");
    retrieveMessen();
    generateDays();
  };

  const generateDays = () => {
    var prevDate = moment(beginDate);
    var dates = [];
    while (prevDate.isBefore(endDate)) {
      dates.push({
        Date: prevDate.clone(),
      });
      prevDate.add(1, "days");
    }
    setDays(dates);
  };

  useEffect(() => {
    retrieveMessen();
    generateDays(endDate);
  }, [endDate, beginDate]);
  return (
    <>
      <div className="container full-width">
        {loading ? (
          <CircleLoader />
        ) : (
          <>
            <button className="btn" onClick={loadBefore}>
              Vorherige laden
            </button>
            <button className="btn" onClick={retrieveMessen.bind(this, true)}>
              Serienmessen generieren
            </button>
            <button
              className="btn"
              onClick={setInCreate.bind(this, {
                Name: "Familienmesse",
                Datum: moment().format("YYYY-MM-DDT11:15"),
                Positionen: [],
              })}
            >
              Einzelmesse erstellen
            </button>
            {/* {days &&
              days.map((d) => (
                <div key={d.Date}>
                  <p
                    onClick={setInCreate.bind(this, {
                      Name: "Familienmesse",
                      Datum: d.Date.format("YYYY-MM-DDT11:15"),
                      Positionen: [],
                    })}
                  >
                    {d.Date.format("DD.MMM YYYY")}
                  </p>
                  {messen.map(
                    (messe) =>
                      moment(messe.Datum).isSame(d.Date, "day") && (
                        <div key={messe.id}>
                          {" " +
                            messe.Name +
                            " um " +
                            moment(messe.Datum).format("HH:mm [Uhr]")}
                          <button
                            className="btn"
                            onClick={setEditMesse.bind(this, messe.id)}
                          >
                            Bearbeiten
                          </button>
                        </div>
                      )
                  )}
                </div>
              ))} */}
            <table>
              <thead></thead>
              <tbody>
                {messen
                  .sort((a, b) => {
                    if (a.Datum < b.Datum) return -1;
                    if (a.Datum > b.Datum) return 1;
                    return 0;
                  })
                  .map((messe) => (
                    <tr>
                      <td>
                        {messe.Name +
                          " am " +
                          moment(messe.Datum).format(
                            "dddd[, den ]DD.MM.[ um ]HH:mm [Uhr]"
                          )}
                      </td>
                      <td>
                        <button
                          className="btn"
                          onClick={setEditMesse.bind(this, messe.id)}
                        >
                          Bearbeiten
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <button className="btn" onClick={loadMore}>
              Mehr laden
            </button>
          </>
        )}
      </div>
      <Popup
        onClose={setEditMesse.bind(this, false)}
        hidden={editMesse === false}
      >
        <EditMesse
          messeId={editMesse}
          onClose={() => {
            setEditMesse(false);
            retrieveMessen();
          }}
        ></EditMesse>
      </Popup>
      <Popup
        onClose={setInCreate.bind(this, false)}
        hidden={inCreate === false}
      >
        <EditMesse
          onClose={() => {
            setInCreate(false);
            retrieveMessen();
          }}
          create={inCreate}
        />
      </Popup>
    </>
  );
};

export default Kalender;

import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "./constants/config";
import authHeader from "./services/auth-header.js";

const http = axios.create({
  baseURL: config.baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

http.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    config.headers["auth-token"] = token;
  }
  return config;
});

http.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    
    const originalConfig = err.config;
    if (err.response) {
      if ([403, 401].includes(err.response.status) && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await http.post("/auth/token", {
            token: localStorage.getItem("refreshToken"),
          });
          const { accessToken } = rs.data;
          localStorage.setItem("authToken", accessToken);
          return http(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
      if (err.response && err.response.status === 401) {
        let navigate = useNavigate();      
        navigate("/login");
      }
    }

  }
);
export default http;

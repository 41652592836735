import Dienablauf from "../components/Dienablauf";
import Dienumfrage from "../components/Dienumfrage/Dienumfrage";
import Dienstatistik from "../components/Dienstatistik";
import MessenZuteilung from "../components/home/MessenZuteilung";
import Kalender from "../components/Kalender/Kalender";
import Abwesenheiten from "../components/Messen/Abwesenheiten";
import Positionen from "../components/Messen/Positionen";
import Serienmessen from "../components/Messen/Serienmessen";
import Nachricht from "../components/Nachricht";
import Nutzerverwaltung from "../components/Nutzer/Nutzerverwaltung";
import Profile from "../components/Nutzer/Profile";
import Dienwuensche from "../components/Nutzer/Dienwuensche";
import Settings from "../components/Settings";
import DienumfrageAdmin from "../components/Dienumfrage/DienumfrageAdmin";
import Home from "../components/home/Home";
import Bestenliste from "../components/Nutzer/Bestenliste";
import moment from "moment";

let mobile = "192.168.178.105";
let berna = "192.168.2.112"; 
let bergamo_cafe = "192.168.1.156";
let bergamo_uni = "172.16.112.39";
let localhost = "localhost";
let thomas = "192.168.178.59";

const route = (name, path, element, permitted = [""]) => {
  return {
    name: name,
    path: path,
    element: element,
    permitted: permitted,
  };
};

export default {
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://dienplan.meheifa.de/api"
      : `http://${localhost}:3000/api`,
  routes: [
    route("M-Plan", "/home", <Home />),
    route("Dienablauf", "/dienablauf", <Dienablauf />, ["dienstat", ""]),
    route("Dienwünsche", "/Dienwuensche", <Dienwuensche />),
    route("Bestenliste", "/Bestenliste", <Bestenliste />),
    route("Profil", "/Profil", <Profile />),
    route("Nutzerverwaltung", "/nutzerverwaltung", <Nutzerverwaltung />, [
      "admin",
    ]),
    route("Serienmessen", "/serienmessen", <Serienmessen />, [
      "admin",
      "mplan",
    ]),
    route("Kalender", "/kalender", <Kalender />, ["admin", "mplan"]),
    route("Positionen", "/positionen", <Positionen />, ["admin", "mplan"]),
    route("Abwesenheitsverwaltung", "/abwesenheiten", <Abwesenheiten />, [
      "admin",
      "mplan",
    ]),
    route("Nachricht", "/nachricht", <Nachricht />, ["admin"]),
    route("Planung", "/planung", <MessenZuteilung />, ["admin", "mplan"]),
    route("Admin-Einstellungen", "/settings", <Settings />, ["admin"]),
    route("Dienstatistik", "/dienstatistik", <Dienstatistik />, ["dienstat"]),
    route("Dienumfrage", "/dienumfrage", <Dienumfrage />, ["dienstat"]),
    route("Dienumfrage-Admin", "/dienumfrageadmin", <DienumfrageAdmin />, [
      "admin",
    ]),
  ],
  spreadsheetImportFields: [
    {
      label: "Vorname",
      key: "Vorname",
      alternateMatches: ["Rufname"],
      fieldType: {
        type: "input",
      },
      example: "Max",
      validations: [
        {
          rule: "required",
          errorMessage: "Vorname wird benötigt",
          level: "error",
        },
      ],
    },
    {
      label: "Nachname",
      key: "Name",
      alternateMatches: ["Name", "Familienname"],
      fieldType: {
        type: "input",
      },
      example: "Messdiener",
      validations: [
        {
          rule: "required",
          errorMessage: "Nachname wird benötigt",
          level: "error",
        },
      ],
    },
    {
      label: "E-Mails",
      key: "Mail_Haupt",
      alternateMatches: ["Email", "e-mail", "E-Mail-Adresse"],
      fieldType: {
        type: "input",
      },
      example: "mama@messdiener.de;papa@messdiener.de",
      validations: [
        {
          rule: /^[\w\-.]+@([\w-]+\.)+[\w-]{2,4}(;[\w\-.]+@([\w-]+\.)+[\w-]{2,4})*$/g,
          errorMessage:
            "Mehrere Mails müssen mit einem ; getrennt und gültig sein.",
          level: "error",
        },
      ],
    },
    {
      label: "MMS",
      key: "MMS",
      alternateMatches: [
        "Handynummer",
        "Nummer",
        "Telefonnummer",
        "Tel",
        "Tel.",
        "Handy",
      ],
      fieldType: {
        type: "input",
      },
      example: "+491234567",
      validations: [
        {
          rule: /\+\d+(;\+\d+)*/g,
          errorMessage:
            "Die Handynummern müssen mit + beginnen und sollen mit einem ; getrennt sein.",
          level: "error",
        },
      ],
    },
    {
      label: "Geburtstag",
      key: "Geburtstag",
      alternateMatches: ["Geb.", "Geb", "Geb.-Datum", "Geb.-Datum"],
      fieldType: {
        type: "input",
      },
      example: "01.01." + moment().subtract(8, "years").year(),
      validations: [
        {
          rule: /\+/g,
          errorMessage: "Geburtstage müssen die Form TT.MM.JJJJ haben.",
          level: "error",
        },
      ],
    },
    {
      label: "Adresse",
      key: "Adresse",
      alternateMatches: ["Straße", "Strasse"],
      example: "Messdienerstr. 1",
      fieldType: {
        type: "input",
      },
    },
    {
      label: "Gruppenstunde",
      key: "Grustu",
      alternateMatches: ["Gruppenstunde", "Grustu"],
      fieldType: {
        type: "input",
      },
      example: moment().year() + "er",
      validations: [
        {
          rule: "required",
          errorMessage: "Gruppenstunde wird benötigt",
          level: "error",
        },
      ],
    },
  ],

  spreadsheetImportTranslation: {
    uploadStep: {
      title: "Datei hochladen",
      manifestTitle: "Daten, die wir erwarten:",
      manifestDescription:
        "(Sie haben die Möglichkeit, Spalten in den nächsten Schritten umzubenennen oder zu entfernen)",
      maxRecordsExceeded: (maxRecords) =>
        `Zu viele Datensätze. Bis zu ${maxRecords} erlaubt`,
      dropzone: {
        title: "Datei im .xlsx, .xls oder .csv Format hochladen",
        errorToastDescription: "Upload abgelehnt",
        activeDropzoneTitle: "Datei hier ablegen...",
        buttonTitle: "Datei auswählen",
        loadingTitle: "Verarbeitung...",
      },
      selectSheet: {
        title: "Blatt auswählen",
        nextButtonTitle: "Weiter",
      },
    },
    selectHeaderStep: {
      title: "Kopfzeile auswählen",
      nextButtonTitle: "Weiter",
    },
    matchColumnsStep: {
      title: "Spalten abgleichen",
      nextButtonTitle: "Weiter",
      userTableTitle: "Ihre Tabelle",
      templateTitle: "Wird zu",
      selectPlaceholder: "Spalte auswählen...",
      ignoredColumnText: "Spalte ignoriert",
      subSelectPlaceholder: "Auswählen...",
      matchDropdownTitle: "Abgleichen",
      unmatched: "Nicht abgeglichen",
      duplicateColumnWarningTitle: "Eine andere Spalte nicht ausgewählt",
      duplicateColumnWarningDescription:
        "Spalten dürfen nicht dupliziert werden",
    },
    validationStep: {
      title: "Daten validieren",
      nextButtonTitle: "Bestätigen",
      noRowsMessage: "Keine Daten gefunden",
      noRowsMessageWhenFiltered: "Keine Daten mit Fehlern gefunden",
      discardButtonTitle: "Ausgewählte Zeilen verwerfen",
      filterSwitchTitle: "Nur Zeilen mit Fehlern anzeigen",
    },
    alerts: {
      confirmClose: {
        headerTitle: "Importvorgang verlassen",
        bodyText:
          "Sind Sie sicher? Ihre aktuellen Informationen werden nicht gespeichert.",
        cancelButtonTitle: "Abbrechen",
        exitButtonTitle: "Vorgang verlassen",
      },
      submitIncomplete: {
        headerTitle: "Fehler erkannt",
        bodyText:
          "Es gibt immer noch einige Zeilen mit Fehlern. Zeilen mit Fehlern werden beim Übermitteln ignoriert.",
        bodyTextSubmitForbidden:
          "Es gibt immer noch einige Zeilen mit Fehlern.",
        cancelButtonTitle: "Abbrechen",
        finishButtonTitle: "Übermitteln",
      },
      unmatchedRequiredFields: {
        headerTitle: "Nicht alle Spalten abgeglichen",
        bodyText:
          "Es gibt erforderliche Spalten, die nicht abgeglichen oder ignoriert wurden. Möchten Sie fortfahren?",
        listTitle: "Nicht abgeglichene Spalten:",
        cancelButtonTitle: "Abbrechen",
        continueButtonTitle: "Fortfahren",
      },
      toast: {
        error: "Fehler",
      },
    },
  },
};

import moment from "moment";
import React, { useState } from "react";
import config from "../../constants/config";
import { Link } from "react-router-dom";
import { hasPermission } from "../../functions/basicFunctions";
const Header = (props) => {
  const [responsive, setResponsive] = useState(false);

  return (
    <section className={`header topnav ${responsive ? "responsive" : ""}`}>
      {config.routes.map((route, index) => (
        <div key={route.name + index}>
          {hasPermission(route.permitted) ? (
            <Link
              className={props.selected === route.path || (props.selected === "/" && route.path === "/home") ? "active" : ""}
              to={route.path}
              onClick={setResponsive.bind(this, false)}              
            >
              {route.name}
            </Link>
          ) : (
            <></>
          )}
        </div>
      ))}      
      <a onClick={setResponsive.bind(this, !responsive)} className="icon">
        <i className="fa fa-bars"></i>
      </a>
    </section>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import "../dienablauf/dienablauf.css";
const Dienablauf = (props) => {

  useEffect(() => {
    let scripts =[];
    for(let path of ["jquery-3.6.0.min.js", "kerzen.js", "akolyten.js","baseFunctions.js", "positionen.js", "jQueryRotate.js", "script.js", "hauptfunktionen.js", "ablauf.js"]) {
      let script = document.createElement("script");
      script.src = path;
      script.async = false ;
      document.body.appendChild(script);
      scripts.push(script);
    }

    return () => {
      scripts.forEach(s => {
        document.body.removeChild(s)
      })
    }
  }, [])


  return (
    <div class="dien-container">
      <div id="altarraum">
        <div id="play" class="blur">
          <div></div>
          <p id="ap-display"></p>
        </div>
        <div id="altarstufe"></div>
        <div id="stufe1"></div>
        <div id="stufe2"></div>
        <div id="stufe3"></div>
        <div id="altar" class="tisch"></div>
        <div class="altar-knie left"></div>
        <div class="altar-knie right"></div>
        <div id="p-sitz" class="stuhl"></div>
        <div id="alt-sitz"></div>
        <div id="k-sitz" class="stuhl k-sitz"></div>
        <div id="k-sitz2" class="stuhl k-sitz"></div>
        <div id="k-knie" class="knie k-knie"></div>
        <div id="k-knie2" class="knie k-knie"></div>
        <div id="ako-sitz" class="stuhl"></div>
        <div id="gabentisch" class="tisch"></div>
        <div id="ambo" class="tisch"></div>
        <div id="akotisch" class="tisch"></div>
        <div id="standkreuz" class="kreuz"></div>
        <div id="standtablett" class="tablett">
          <div class="schale1 standkerze"></div>
          <div class="schale2 standkerze"></div>
        </div>
        <div id="standevangelium" class="evangelium"></div>
        <p id="description"></p>
      </div>

      <div id="progressbar">
        <h1 id="state">Test</h1>
        <div id="progress"></div>
      </div>
      <p id="timeDisplay"></p>
      <div id="descDisplay" class="dien-display">
        <button id="prev" class="nav-button">
          &lt;
        </button>
        <p id="dispText">Was möchtest du tun?</p>
        <button id="next" class="nav-button">
          &gt;
        </button>
        <p id="correct">Correct!</p>
        <p id="false">Sicher?, du kannst es einfach nochmal versuchen ;)</p>
      </div>
      <div id="bottomDisplay" class="">
        <div class="dien-menu">
          <div id="type" class="btnrow">
            <button id="back" class="dienablauf-btn">
              Zurück
            </button>
            <button id="continue" class="dienablauf-btn">
              Weiter
            </button>
            <button id="retry" class="dienablauf-btn">
              Nochmal
            </button>
            <button id="trainbtn" class="dienablauf-btn">
              Üben
            </button>
            <button id="quizbtn" class="dienablauf-btn">
              Quiz
            </button>
            <button id="wotimebtn" class="dienablauf-btn">
              Ohne Zeit
            </button>
            <button id="wtimebtn" class="dienablauf-btn">
              Auf Zeit (für Profis)
            </button>
          </div>
        </div>
        <div id="quiz" class="dien-menu"></div>
      </div>
      <div class="ablauf dien-display">
        <div>
          <h3>Wortgottesdienst</h3>
          <ol id="wortgottesdienst" class="navigation"></ol>
        </div>
        <div>
          <h3>Eucharistiefeier</h3>
          <ol id="eucharistie" class="navigation"></ol>
        </div>
      </div>
      <div id="hm" class="dien-menu">
        <div class="btnrow">
          <button id="home" class="dienablauf-btn">
            Zurück zum Anfang
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dienablauf;

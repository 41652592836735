import React, { useEffect, useState } from "react";
import kalenderService from "../../services/kalender.service";
import moment from "moment";
import adminService from "../../services/admin.service";
import userService from "../../services/user.service";
import Nutzerstatistik from "./Nutzerstatistik";
import CircleLoader from "react-spinners/CircleLoader";
import { b64toBlob, saveBlob } from "../../functions/basicFunctions";

const MessenZuteilung = (props) => {
  const [messen, setMessen] = useState([]);
  const [posDict, setPosDict] = useState();
  const [allNames, setAllNames] = useState([]);
  const [beginDate, setBeginDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(
    moment().add(3, "months").format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(true);
  const [scrollState, setScrollState] = useState();

  const genMplan = () => {
    kalenderService.mplanAusgeben(beginDate, endDate).then((res) => {
      saveBlob(
        b64toBlob(res.data.blob, res.headers["content-type"]),
        `MPlan_${
          moment(beginDate).format("MM_YY") +
          "_" +
          moment(endDate).format("MM_YY")
        }.docx`
      );
    });
  };

  const getDienwuensche = () => {
    kalenderService.getDienwuensche(beginDate, endDate).then((res) => {
      saveBlob(new Blob([res.data], { type: "text/csv" }), "Dienwuensche.csv");
    });
  };

  const retrieveMessen = () => {
    setLoading(true);
    kalenderService.getMessenMitDienern(beginDate, endDate).then((res) => {
      setMessen(res.data);
      setLoading(false);
      setTimeout(() => {
        window.scrollTo(0, scrollState);
      }, 200);
    });
  };

  const einteilen = () => {
    if (
      window.confirm(
        "Alle fehlenden Rollen zwischen dem " +
          moment(beginDate).format("DD.MM.YY") +
          " und " +
          moment(endDate).format("DD.MM.YY") +
          " zuteilen?"
      )
    ) {
      kalenderService.createDienplan(beginDate, endDate).then((res) => {
        retrieveMessen();
      });
    }
  };

  const resetZuteilung = () => {
    if (
      window.confirm(
        "Alle Zuteilungen zwischen dem " +
          moment(beginDate).format("DD.MM.YY") +
          " und " +
          moment(endDate).format("DD.MM.YY") +
          " entfernen?"
      )
    ) {
      kalenderService.clearZuteilung(beginDate, endDate).then((res) => {
        retrieveMessen();
      });
    }
  };

  const retrievePosDict = () => {
    kalenderService.getPosDict().then((res) => {
      setPosDict(res.data);
    });
  };
  const retrieveUsers = () => {
    userService.getAllNames().then((res) => {
      var nameDict = {};
      res.data.forEach((user) => {
        nameDict[user.id] = user.Name + ", " + user.Vorname;
      });
      setAllNames(nameDict);
    });
  };

  const changeMessdiener = (event, messeId, posId, index) => {
    const userId = event.target.value !== "" ? event.target.value : null;
    kalenderService
      .changeMessdiener(messeId, userId, posId, index)
      .then((res) => {
        retrieveMessen();
      });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollState(window.pageYOffset);
    });
    retrieveUsers();
    retrievePosDict();
  }, []);

  useEffect(() => {
    retrieveMessen();
  }, [beginDate, endDate]);

  return (
    <div className="container full-width">
      <h1>Messenaufteilung</h1>
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          <div className="btn-container">
            <button className="btn" onClick={einteilen}>
              Einteilen
            </button>
            <button className="btn" onClick={resetZuteilung}>
              Zuteilungen entfernen
            </button>
            <button className="btn" onClick={genMplan}>
              Mplan ausgeben
            </button>
            <button className="btn" onClick={getDienwuensche}>
              Dienwunschtabelle
            </button>
          </div>
          Messen vom
          <input
            type="date"
            onChange={(event) => {
              setBeginDate(event.target.value);
            }}
            value={beginDate}
          />
          bis
          <input
            type="date"
            onChange={(event) => {
              setEndDate(event.target.value);
            }}
            value={endDate}
          />
          {messen.length > 0 &&
            messen.map((messe, messeIndex) => (
              <div key={messe._id} className="messe-planung">
                <h4>
                  {messe.Name +
                    " am " +
                    moment(messe.Datum).format("DD.MM. [um] HH:mm [Uhr:]")}
                </h4>
                1. MMS: {!messe.MMS1Send ? "steht aus." : "gesendet."}
                2. MMS: {!messe.MMS2Send ? "steht aus." : "gesendet."}
                <table>
                  <tbody>
                    {posDict &&
                      messe.Positionen.map(
                        (pos, posIndex) =>
                          pos.messdiener.length > 0 && (
                            <div>
                              {pos.messdiener.map((m, index) => (
                                <tr key={messe._id + pos.position + index + m}>
                                  <td>
                                    <select
                                      onChange={(event) =>
                                        changeMessdiener(
                                          event,
                                          messe._id,
                                          pos.position,
                                          index
                                        )
                                      }
                                      value={""}
                                    >
                                      <option value="">
                                        {m !== null ? allNames[m] : "Auswählen"}
                                      </option>
                                      {m !== null ? (
                                        <option value="">Auswählen</option>
                                      ) : (
                                        <></>
                                      )}
                                      {messe.Available[pos.position].map(
                                        (userId, uIndex) => (
                                          <option
                                            key={
                                              userId.id +
                                              messe._id +
                                              pos.position +
                                              index
                                            }
                                            value={
                                              messen[messeIndex].Available[
                                                pos.position
                                              ][uIndex].id
                                            }
                                          >
                                            {allNames[userId.id]}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </td>
                                  <td>{posDict[pos.position]}</td>
                                </tr>
                              ))}
                            </div>
                          )
                      )}
                  </tbody>
                </table>
              </div>
            ))}
        </>
      )}
      {/* <div style={{ flex: "30%" }}>
        <Nutzerstatistik beginDate={beginDate} endDate={endDate} />
      </div>
                                            </div>*/}
    </div>
  );
};

export default MessenZuteilung;

import React, { useEffect, useState } from "react";
import moment from "moment";
import {ReactComponent as TrashLogo} from "../../assets/trash.svg";
const EditAbwesenheitProfile = (props) => {
  const [editAbwesenheit, setEditAbwesenheit] = useState(null);

  const updateAbwesenheit = (event) => {
    let abw = { ...editAbwesenheit };
    if (event.target.name === "Start") {
      abw["Start"] = moment(event.target.value).startOf("day");
      if (moment(abw.Ende).isBefore(moment(event.target.value)))
        abw.Ende = moment(event.target.value).endOf("day");
    } else if (event.target.name === "Ende") {
      abw["Ende"] = moment(event.target.value).endOf("day");
      if (moment(abw.Start).isAfter(moment(event.target.value)))
        abw.Start = moment(event.target.value).startOf("day");
    } else {
      abw[event.target.name] = event.target.value;
    }
    setEditAbwesenheit(abw);
  };

  const addAbwesenheit = (e) => {
    e.preventDefault();
    let abs = [...props.abwesenheit];
    for (let i = 0; i <= abs.length; i++) {
      if (i === abs.length - 1 || abs.length === 0) {
        abs.push(editAbwesenheit);
        break;
      }
      if (editAbwesenheit.Start.diff(moment(abs[i].Start)) < 0) {
        abs.splice(i, 0, editAbwesenheit);
        break;
      }
    }

    let event = {
      target: {
        value: abs,
        name: "Abwesenheit",
      },
    };

    props.onChange(event);
    setEditAbwesenheit(null);
  };

  const createAbwesenheit = (e) => {
    setEditAbwesenheit({
      Start: moment(),
      Ende: moment().endOf("day"),
      Grund: "",
    });
  };

  const removeAbwesenheit = (i) => {
    let abs = [...props.abwesenheit];
    abs.splice(i, 1);
    props.onChange({ target: { value: abs, name: "Abwesenheit" } });
  };

  return (
    <>
      {props.abwesenheit.length > 0 &&
        props.abwesenheit
          .sort((a, b) => {
            if (a.Start < b.Start) return -1;
            if (a.Start > b.Start) return 1;
            if (a.Ende < b.Ende) return -1;
            if (a.Ende > b.Ende) return 1;
            return 0;
          })
          .filter((ab) => moment(ab.Ende).isAfter(moment()))
          .map((ab, i) => (
            <div key={ab._id ?? i}>
              {moment(ab.Start).isSame(moment(ab.Ende), "day")
                ? moment(ab.Start).format("DD.MM.YYYY")
                : `${moment(ab.Start).format("DD.MM.YYYY")} bis ${moment(
                    ab.Ende
                  ).format("DD.MM.YYYY")}${
                    ab.Grund.length > 0 ? ` (${ab.Grund})` : ""
                  } `}
              <button
                type="button"
                className="btn-style"
                onClick={removeAbwesenheit.bind(this, i)}
              >
                <TrashLogo className="btn-logo" height="1.3rem" width="1.3rem"/>
              </button>
            </div>
          ))}
      {editAbwesenheit && (
        <div>
          <input
            type="date"
            name={"Start"}
            value={moment(editAbwesenheit.Start).format("YYYY-MM-DD")}
            onChange={(e) => {
              updateAbwesenheit(e);
            }}
          ></input>{" "}
          bis{" "}
          <input
            type="date"
            name={"Ende"}
            value={moment(editAbwesenheit.Ende).format("YYYY-MM-DD")}
            onChange={(e) => {
              updateAbwesenheit(e);
            }}
          ></input>{" "}
          <input
            type="text"
            name="Grund"
            placeholder={"opt. Grund"}
            value={editAbwesenheit.Grund}
            onChange={(e) => {
              updateAbwesenheit(e);
            }}
          ></input>
          <button
            type="button"
            className="btn"
            onClick={addAbwesenheit.bind(this)}
          >
            Speichern
          </button>
          <button
            type="button"
            className="btn"
            onClick={setEditAbwesenheit.bind(this, null)}
          >
            Abbrechen
          </button>
        </div>
      )}
      <button type="button" className="btn" onClick={createAbwesenheit}>
        Neue Abwesenheit
      </button>
    </>
  );
};

export default EditAbwesenheitProfile;

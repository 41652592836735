import React, { useEffect, useState } from "react";
import kalenderService from "../../services/kalender.service";
import moment from "moment";
import SerienmesseElement from "./SerienmesseElement";
import EditSerienmesse from "./EditSerienmesse";
import Popup from "../base/Popup";
import CircleLoader from "react-spinners/CircleLoader";
const Serienmessen = (props) => {
  const [messen, setMessen] = useState([]);
  const [createSerienmesse, setCreateSerienmesse] = useState(false);
  const [editMesse, setEditMesse] = useState(null);
  const [loading, setLoading] = useState(true);

  const retrieveMessen = () => {
    kalenderService.getSerienmessen().then((res) => {
      setMessen(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    retrieveMessen();
  }, []);
  return (
    <div className="container">
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>Serienmesse</th>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {messen &&
                messen.map((messe) => (
                  <SerienmesseElement
                    messe={messe}
                    key={messe._id}
                    onEdit={setEditMesse.bind(this, messe)}
                  />
                ))}
            </tbody>
          </table>
          <button className="btn" onClick={setCreateSerienmesse.bind(this, true)}>
            Neuen Serienmesse anlegen
          </button>
          <Popup
            hidden={!createSerienmesse}
            onClose={setCreateSerienmesse.bind(this, false)}
          >
            <EditSerienmesse
              create
              key={"create"}
              messe={{
                Datum: moment(),
                Uhrzeit: "",
                Series: { Datum: moment().toLocaleString() },
                Positionen: [],
                _id: "create",
              }}
              retrieveMessen={retrieveMessen}
            />
          </Popup>
          <Popup
            hidden={editMesse === null ? true : false}
            onClose={setEditMesse.bind(this, null)}
          >
            {editMesse && (
              <EditSerienmesse
                key={editMesse._id}
                messe={{ ...editMesse, WhichToEdit: { Ab: 0 } }}
                retrieveMessen={retrieveMessen}
              />
            )}
          </Popup>
        </>
      )}
    </div>
  );
};

export default Serienmessen;

import React, { useEffect, useState } from "react";
import userService from "../../services/user.service";
import CircleLoader from "react-spinners/CircleLoader";
import Messe from "../Messen/Messe";

const Home = (props) => {
  const [showOnlyOwnMesses, setShowOnlyOwnMesses] = useState(true);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [namen, setNamen] = useState();
  const [ownId, setOwnId] = useState();
  const [currentMesse, setCurrentMesse] = useState();

  const retrieveUser = () => {
    userService.getUser().then((user) => {
      setUser(user.data);
      setLoading(false);
    });
  };
  const retrieveNamen = () => {
    userService.getAllNames().then((res) => {
      let names = [];
      for (let u of res.data) {
        names[u.id] = { Vorname: u.Vorname, Name: u.Name };
      }
      setNamen(names);
    });
  };

  const retrieveUserId = () => {
    let id = localStorage.getItem("user");
    setOwnId(id);
  };

  useEffect(() => {
    retrieveUser();
    retrieveNamen();
    retrieveUserId();
  }, []);

  return (
    <div className="container">
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          {user && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="equal-column">
                <h3>Hi {user.Vorname},</h3>

                <p>
                  {user.messen.length > 0
                    ? "Deine nächste Messe:"
                    : "Du bist momentan für keine Messen eingetragen, warte auf den nächsten Dienplan:)"}
                </p>
                {user.messen.length > 0 && (
                  <Messe
                    messeId={user.messen[0].id}
                    namen={namen}
                    user={user}
                    ownId={ownId}
                  />
                )}
              </div>
              <div className="equal-column">
                {currentMesse ? (
                  <>
                    <button className="back-button" onClick={setCurrentMesse.bind(this, false)}>
                      <i className="fas fa-arrow-left"></i>
                    </button>
                    <Messe
                      messeId={currentMesse}
                      namen={namen}
                      user={user}
                      ownId={ownId}
                    />
                  </>
                ) : (
                  <>
                    <h4>Dienplan:</h4>
                    {user.allMessen.map(
                      (messe, index) => (
                        <div
                          className="messe-btn"
                          style={user["messen"].some((m) => m.id === messe.id) ? {
                            color:  "red",
                            fontWeight: "bold"
                          }:{}}
                          onClick={setCurrentMesse.bind(this, messe.id)}
                        >
                          {messe.title}
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Home;

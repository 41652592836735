import React, { useEffect, useState } from "react";
import adminService from "./../services/admin.service";
import TagsInput from "./base/TagsInput";
import userService from "./../services/user.service";
import CircleLoader from "react-spinners/CircleLoader";

const Settings = (props) => {
  const [roles, setRoles] = useState(false);
  const [emptyRole, setEmptyRole] = useState({ name: "", messdiener: [] });
  const [allNames, setAllNames] = useState();
  const [loading, setLoading] = useState(true);

  const handleEmptyRole = (event) => {
    var obj = { ...emptyRole };
    obj[event.target.name] = event.target.value;
    setEmptyRole(obj);
  };

  const addRole = () => {
    adminService.addRole(emptyRole).then(() => {
      retrieveRoles();
    });
  };

  const retrieveRoles = () => {
    adminService.getRoles().then((res) => {
      userService.getAllNames().then((names) => {
        setAllNames(names.data);
        setRoles(res.data);
        setLoading(false);
      });
    });
  };

  const handleInputChange = (event, i) => {
    adminService.updateRole(roles[i].name, event.target.value).then((res) => {
      retrieveRoles();
    });
  };

  useEffect(() => {
    retrieveRoles();
  }, []);
  return (
    <div className="container full-width">
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>Rolle</th>
                <th>Messdiener</th>
              </tr>
            </thead>
            <tbody>
              {roles &&
                roles.map((role, index) => (
                  <tr key={role.name}>
                    <td>{role.name}</td>
                    <td>
                      <TagsInput
                        tags={role.messdiener}
                        users={allNames}
                        name={role.name}
                        onChange={(event) => handleInputChange(event, index)}
                      />
                    </td>
                  </tr>
                ))}

              <tr>
                <td>
                  <input
                    type="text"
                    name="name"
                    placeholder="Neue Rolle"
                    value={emptyRole.name}
                    onChange={handleEmptyRole}
                  />
                </td>
                <td>
                  <TagsInput
                    tags={emptyRole.messdiener}
                    users={allNames}
                    name="messdiener"
                    onChange={handleEmptyRole}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button className="btn" onClick={addRole}>
            Neue Rolle hinzufügen
          </button>
        </>
      )}
    </div>
  );
};

export default Settings;

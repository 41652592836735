import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import TagsInput from "../base/TagsInput";
import userService from "../../services/user.service";
import {
  arrayAttr,
  messeAttr,
  objectAttr,
} from "../../constants/userAttributes";
import CircleLoader from "react-spinners/CircleLoader";
import Popup from "../base/Popup";
import ChangePassword from "./ChangePassword";

const Profile = (props) => {
  const [inPassChange, setInPassChange] = useState(false);
  const requiredAttributes = {
    Vorname: { pretty: "Vorname", desc: "" },
    Name: { pretty: "Nachname", desc: "" },
    Grustu: { pretty: "Gruppenstunde", desc: "" },
  };
  const optionalAttributes = {
    Mail_Haupt: {
      pretty: "Hauptmail",
      desc: "An diese Mails werden alle Mails versandt.",
    },
    MMS: { pretty: "MMS", desc: "An diese Nummern werden deine MMS versandt." },
    Adresse: { pretty: "Adresse", desc: "" },
    Mail_Erinnerung: { pretty: "Erinnerungsmail", desc: "" },
  };

  const [loading, setLoading] = useState(true);
  const [inputState, setInputState] = useState(false);
  const [messen, setMessen] = useState();
  const [message, setMessage] = useState();

  const handleInputChange = (event) => {
    var state = { ...inputState };
    state[event.target.name] = event.target.value;
    setMessage("Du hast ungespeicherte Änderungen.");

    setInputState(state);
  };

  const retrieveUser = () => {
    userService.getUser().then((res) => {
      setInputState(res.data);
      setLoading(false);
    });
  };

  const editUser = (event = false, state = false) => {
    if (event) event.preventDefault();
    userService.editUser(state ? state : inputState).then((res) => {
      setMessage("Änderungen wurden gespeichert!");
    });
  };

  useEffect(() => {
    retrieveUser();
  }, []);

  return (
    <div className="container">
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          {inputState && (
            <form className="edit-form" onSubmit={editUser}>
              <table>
                <tbody>
                  {Object.keys(requiredAttributes).map((attr) => (
                    <tr key={attr}>
                      <th data-tip data-for={"tooltip" + attr}>
                        {requiredAttributes[attr].pretty}{" "}
                        <ReactTooltip
                          id={"tooltip" + attr}
                          place="top"
                          effect="solid"
                        >
                          {requiredAttributes[attr].desc}
                        </ReactTooltip>
                      </th>
                      <td>
                        <input
                          type="text"
                          name={attr}
                          value={inputState[attr]}
                          placeholder={requiredAttributes[attr].pretty}
                          onChange={handleInputChange}
                          required
                          disabled
                        />
                      </td>
                    </tr>
                  ))}
                  {Object.keys(optionalAttributes).map((attr) => (
                    <tr key={attr}>
                      <th>{optionalAttributes[attr].pretty}</th>
                      <td data-tip data-for={"tooltip" + attr}>
                        {optionalAttributes[attr].desc.length > 0 && (
                          <ReactTooltip
                            id={"tooltip" + attr}
                            place="top"
                            effect="solid"
                          >
                            {optionalAttributes[attr].desc}
                          </ReactTooltip>
                        )}
                        {arrayAttr.includes(attr) ? (
                          <TagsInput
                            name={attr}
                            pretty={optionalAttributes[attr].pretty}
                            tags={inputState[attr]}
                            onChange={handleInputChange}
                          />
                        ) : (
                          <input
                            type="text"
                            name={attr}
                            value={inputState[attr]}
                            placeholder={optionalAttributes[attr].pretty}
                            onChange={handleInputChange}
                            key={attr}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <th>Geburtstag</th>
                    <td>
                      <input
                        type="date"
                        name="Geburtstag"
                        value={moment(inputState["Geburtstag"]).format(
                          "yyyy-MM-DD"
                        )}
                        placeholder="Geburtstag"
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Passwort
                    </th>
                    <td><button
                        className="btn-style"
                        type="button"
                        onClick={setInPassChange.bind(this, true)}
                      >
                        {"Passwort ändern"}
                      </button></td>
                  </tr>
                </tbody>
              </table>
              <input type="submit" className="btn" value="Speichern" />
              <button
                className="btn"
                type="button"
                onClick={() => {
                  if (window.confirm("Möchtest du dich wirklich ausloggen?")) {
                    props.handleLogout();
                  }
                }}
                value={"Logout"}
              >
                Ausloggen
              </button>
              {message && <>{" " + message}</>}
            </form>
          )}
          <Popup
            onClose={setInPassChange.bind(this, false)}
            hidden={!inPassChange}
          >
            <ChangePassword />
          </Popup>
          <></>
        </>
      )}
    </div>
  );
};

export default Profile;

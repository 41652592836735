import React, { useEffect, useState } from "react";
import kalenderService from "../../services/kalender.service";
import moment from "moment";
import adminService from "../../services/admin.service";
import EditNutzerAdmin from "./EditNutzerAdmin";
import { arrayAttr } from "../../constants/userAttributes";
import Popup from "../base/Popup";
import CircleLoader from "react-spinners/CircleLoader";
import config from "../../constants/config.js";
import { b64toBlob, saveBlob } from "../../functions/basicFunctions";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";

const Nutzerverwaltung = (props) => {
  const [users, setUsers] = useState([]);
  const [tableAttributes, setTableAttributes] = useState([]);
  const [sortBy, setSortBy] = useState({ key: "", inverse: false });
  const [showNewUser, setShowNewUser] = useState(false);
  const [editId, setEditId] = useState(null);
  const [inputState, setInputState] = useState({});
  const [showImport, setShowImport] = useState(false);
  const [bulkEditUsers, setBulkEditUsers] = useState([]); //[{id: 1, checked: true}, {id: 2, checked: false}
  const [bulkEditAction, setBulkEditAction] = useState("delete"); //activate, delete
  const [isMobile, setIsMobile] = useState(false);

  const requiredAttributes = ["Vorname", "Name", "Grustu"];
  const optionalAttributes = ["Mail_Haupt", "MMS", "Mail_Erinnerung"];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setUsers(sort([...users]));
  }, [sortBy]);

  const sort = (us) => {
    const { key, inverse } = sortBy;
    return us.sort((a, b) => {
      if (a[key] === null && b[key] !== null) return inverse ? -1 : 1;
      if (a[key] !== null && b[key] === null) return inverse ? 1 : -1;
      if (a[key] < b[key]) return inverse ? 1 : -1;
      if (a[key] > b[key]) return inverse ? -1 : 1;
      return 0;
    });
  };

  const handleResize = () => {
    if (window.outerWidth <= 600) {
      setTableAttributes(["Name", "Vorname"]);
      setIsMobile(true);
    } else {
      setTableAttributes([
        "Name",
        "Vorname",
        "Mail_Haupt",
        "MMS",
        "Username",
        "Grustu",
      ]);
      setIsMobile(false);
    }
  };

  const handleInputChange = (event, target) => {
    var state = { ...inputState };
    state[event.target.name] = event.target.value;
    setInputState(state);
  };

  const retrieveUsers = () => {
    adminService.getUsers(0, 200).then((res) => {
      setBulkEditUsers(
        res.data.map((user) => ({ id: user.id, checked: false }))
      );
      setUsers(sort(res.data));
      
      setLoading(false);
    });
  };

  const createUser = (event) => {
    event.preventDefault();
    adminService.createUser({...inputState, Mail_Haupt: [inputState.Mail_Haupt]}).then((res) => {
      retrieveUsers();
      setShowNewUser(false);
    });
  };

  const tabelleAusgebenClicked = () => {
    adminService.tabelleAusgeben().then((res) => {
      saveBlob(
        b64toBlob(res.data.blob, res.headers["content-type"]),
        `Adressliste_${moment().format("MM_YY")}.xlsx`
      );
    });
  };

  const createUsers = (data, file) => {
    let usersToCreate = data.validData;
    //convert MMS and Mail_Haupt to array
    usersToCreate = usersToCreate.map((user) => {
      if(user.MMS) user.MMS = user.MMS.split(";").filter((phone) => phone.length > 0);
      //unique Mails with lowercase checking
      if(user.Mail_Haupt) user.Mail_Haupt = [...new Set(user.Mail_Haupt.toLowerCase().split(";"))];
      if(user.Geburtstag) user.Geburtstag = Date.parse(user.Geburtstag);
      return user;
    });
    adminService.createMultipleUsers(usersToCreate).then((res) => {
      retrieveUsers();
      setShowImport(false)
    });
  };

  useEffect(() => {
    retrieveUsers();
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="container full-width">
      <ReactSpreadsheetImport
        isOpen={showImport}
        onClose={setShowImport.bind(this, false)}
        onSubmit={createUsers}
        rowHook={(data, addError) => {
          if(data.MMS){
            let phone_numbers = data.MMS.split(";")
              .filter((phone) => phone && phone.length > 0)
              .map((phone) => {
                //delete all non_numbers
                phone = phone.replace(/[^0-9+]/g, "");
                //replace leading 0 with +49
                if (phone.startsWith("0")) phone = phone.replace("0", "+49");
                if (!phone.startsWith("+49")) {
                  addError("MMS", {
                    message: "Die Telefonnummer muss mit +49 beginnen.",
                    level: "error",
                  });
                }
                if (phone.length > 0) return phone;
              });
            data.MMS = phone_numbers.join(";");
          }
          if(data.Mail_Haupt){
            let mails = data.Mail_Haupt.split(";")
              .filter((mail) => mail && mail.length > 0)
              .map((mail) => {
                //check if mail is in valid Mail Format (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)), otherwise raise Error
                let mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (!mailRegex.test(mail)) {
                  addError("Mail_Haupt", {
                    message: "Die E-Mail Adresse ist nicht gültig.",
                    level: "error",
                  });
                }
                return mail;
              });
            data.Mail_Haupt = mails.join(";");
          }
            return data;
        }}
        translations={config.spreadsheetImportTranslation}
        fields={config.spreadsheetImportFields}
      />
      {loading ? (
        <CircleLoader />
      ) : (
        <>
          <button className="btn" onClick={tabelleAusgebenClicked}>
            Tabelle ausgeben
          </button>
          <button className="btn" onClick={setShowImport.bind(this, true)}>
            Daten importieren
          </button>
          <table>
            <thead>
              <tr>
                {!isMobile && (
                  <th style={{width: "10rem"}}>
                    {bulkEditUsers.some((user) => user.checked) ? (
                      <>
                        <select
                          name="bulkEdit"
                          id="bulkEdit"
                          value={bulkEditAction}
                          onChange={(event) => {
                            setBulkEditAction(event.target.value);
                          }}
                        >
                          <option value="activate">Aktivieren</option>
                          <option value="delete">Löschen</option>
                        </select>
                        <button
                          onClick={() => {
                            for (let userBulk of bulkEditUsers.filter(
                              (user) => user.checked
                            )) {
                              //get Name from users
                              let user = users.find(
                                (u) => u.id === userBulk.id
                              );
                              if (
                                window.confirm(
                                  `Bist du dir sicher, dass du ${
                                    user.Vorname
                                  } ${user.Name} ${
                                    bulkEditAction === "activate"
                                      ? "aktivieren"
                                      : "löschen"
                                  } möchtest?`
                                )
                              ) {
                                if (bulkEditAction === "activate") {
                                  adminService.activateUser(userBulk.id);
                                } else if (bulkEditAction === "delete") {
                                  adminService.deleteUser(userBulk.id);
                                }
                              }
                            }
                          }}
                          className="btn"
                        >
                          Ausführen
                        </button>
                      </>
                    ) : (
                      "Auswählen"
                    )}
                  </th>
                )}
                {tableAttributes.map((key) => (
                  <th
                    key={key}
                    onClick={() => {
                      let s = { ...sortBy };
                      s.inverse = s.key === key ? !s.inverse : false;
                      s.key = key;
                      setSortBy(s);
                    }}
                  >
                    {key}
                  </th>
                ))}
                <th>
                  <button
                    className="btn"
                    onClick={setShowNewUser.bind(this, true)}
                  >
                    Neuen Benutzer anlegen
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, index) => (
                  <tr
                    key={user.id}
                    className={`table-row ${index % 2 ? "" : "table-alt"} ${
                      user.Inaktiv === true ? "inactive-user" : ""
                    }`}
                  >
                    {!isMobile && bulkEditUsers && (
                      <td>
                        <input
                          type="checkbox"
                          checked={
                            bulkEditUsers.find(
                              (bulkUser) => user.id === bulkUser.id
                            ).checked
                          }
                          onChange={(event) => {
                            let newBulkEditUsers = [...bulkEditUsers];
                            newBulkEditUsers.find(
                              (bulkUser) => user.id === bulkUser.id
                            ).checked = event.target.checked;
                            setBulkEditUsers(newBulkEditUsers);
                          }}
                        />
                      </td>
                    )}
                    {tableAttributes.map((key) => (
                      <td key={user.id + key}>
                        {arrayAttr.includes(key) ? (
                          <>
                            {user[key].map(
                              (k, index) =>
                                k +
                                `${index === user[key].length - 1 ? "" : ", "}`
                            )}
                          </>
                        ) : (
                          user[key]
                        )}
                      </td>
                    ))}
                    <td>
                      <button
                        className="btn"
                        onClick={setEditId.bind(this, user.id)}
                      >
                        Bearbeiten
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Popup
            hidden={!showNewUser}
            onClose={setShowNewUser.bind(this, false)}
          >
            <div className="edit-nutzer-admin">
              <h2>Neuen Benutzer erstellen</h2>
              <form onSubmit={createUser}>
                <table>
                  <tbody>
                    {requiredAttributes.map((attr) => (
                      <tr key={attr}>
                        <td>{attr}*</td>
                        <td>
                          <input
                            type="text"
                            name={attr}
                            value={inputState[attr]}
                            placeholder={attr}
                            onChange={handleInputChange}
                            required
                          />
                        </td>
                      </tr>
                    ))}
                    {optionalAttributes.map((attr) => (
                      <tr key={attr}>
                        <td>{attr}</td>
                        <td>
                          <input
                            type="text"
                            name={attr}
                            value={inputState[attr]}
                            placeholder={attr}
                            onChange={handleInputChange}
                            key={attr}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>Geburtstag</td>
                      <td>
                        <input
                          type="date"
                          name="Geburtstag"
                          value={inputState["Geburtstag"]}
                          placeholder="Geburtstag"
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Adresse</td>
                      <td>
                        <input
                          type="text"
                          name="Adresse"
                          value={inputState["Adresse"]}
                          placeholder="Adresse"
                          onChange={handleInputChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <input type="submit" className="btn" value="Abschicken" />
              </form>
            </div>
          </Popup>
          <Popup
            hidden={editId ? false : true}
            onClose={() => {
              setEditId(null);
            }}
          >
            <EditNutzerAdmin
              key={editId}
              userId={editId}
              onEdit={() => {
                retrieveUsers();
                setEditId(null);
              }}
            />
          </Popup>
        </>
      )}
    </div>
  );
};

export default Nutzerverwaltung;
